<template>
  <div id="Productremark" class="row">
    <div class="col-12 p-5 shadow p-3 mb-3 bg-white rounded">
      <p class="font-1-2s font-600">Remark ของสินค้า</p>
      <div class="row justify-contnet-end">
        <div class="col-12">
          <div class="col-12 p-0 mb-3">
            <div class="row">
              <p class="font-600">หมายเหตุ Short Description</p>
            </div>
            <div>
              <p v-html="data.RemarkShortDesc"></p>
              <p v-html="data.RemarkShortDescAll"></p>
            </div>
          </div>
          <div class="col-12 p-0 mb-3">
            <div class="row">
              <p class="font-600">หมายเหตุ Long Description</p>
              <b-form-checkbox class="ml-2" switch v-model="data.remarkId">
              </b-form-checkbox>
            </div>
            <div v-if="data.remarkId">
              <p v-html="data.RemarkLongdesc"></p>
              <p v-html="data.RemarkLongDescAll"></p>
            </div>
            <div class="alert alert-light" role="alert" v-else>
              <p v-html="data.RemarkLongdesc"></p>
              <p v-html="data.RemarkLongDescAll"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modalremarks :key="reModalremarks" @get_data="get_data" />
  </div>
</template>

<script>
import tinymce from "vue-tinymce-editor";
import Modalremarks from "@/components/Products/EnrichProductDetail/Modalremarks.vue";
export default {
  components: { tinymce, Modalremarks },
  data() {
    return {
      // Tinymce Editor
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 150,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      // End Tinymce Editor
      reModalremarks: Date.now(),
      data: {
        remarkId: false,
        RemarkShortDesc: "",
        RemarkShortDescAll: "",
        RemarkLongdesc: "",
        RemarkLongDescAll: "",
      },
    };
  },
  watch: {
    com_get_data: function(v) {
      this.get_data();
    },
    "data.remarkId": async function(value) {
      this.data.remarkId = value;
      await this.$store.dispatch(
        "enrichproductdetailActions/setremark",
        this.data
      );
    },
  },
  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    callmodalremark() {
      this.reModalremarks = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalremarks");
      }, 100);
    },
    async get_data() {
      // this.redata();
      let data = this.$store.getters["enrichproductdetailActions/get_data"];
      if (data !== null) {
        const {
          RemarkLongDescAll,
          RemarkLongdesc,
          RemarkShortDesc,
          RemarkShortDescAll,
          remarkId,
        } = data;
        this.data = {
          RemarkLongDescAll: RemarkLongDescAll,
          RemarkLongdesc: RemarkLongdesc,
          RemarkShortDesc: RemarkShortDesc,
          RemarkShortDescAll: RemarkShortDescAll,
          remarkId:
            String(remarkId) == "null" || String(remarkId) == "0"
              ? false
              : true,
        };
      }
    },
  },
};
</script>

<style></style>
