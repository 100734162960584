<template>
  <div id="Productother" class="row">
    <div class="col-12 p-5 shadow p-3 mb-3 bg-white rounded">
      <p class="font-1-2s font-600">อื่น ๆ</p>
      <!-- ---------------- Mark Flag --------------- -->
      <div class="row mb-3">
        <div class="col-12">
          <p>Mark Flag</p>
          <hr />
        </div>
        <!-- ----------------------------- -->
        <div class="col-12 col-lg-6">
          <div class="form-check mb-3">
            <input id="bulky" class="form-check-input" type="checkbox" value="1" v-model="data._bulky" />
            <label class="form-check-label" for="bulky">
              สินค้าชิ้นใหญ่
            </label>
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="col-12 col-lg-6">
          <div class="form-check mb-3">
            <input id="fragile" class="form-check-input" type="checkbox" value="1" v-model="data._fragile" />
            <label class="form-check-label" for="fragile">
              สินค้าเสี่ยงเเตก
            </label>
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="col-12 col-lg-6">
          <div class="form-check mb-3">
            <input id="explosives_flammable" class="form-check-input" type="checkbox" value="1"
              v-model="data._explosives_flammable" />
            <label class="form-check-label" for="explosives_flammable">
              วัตถุไวไฟ/ระเบิด
            </label>
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="col-12 col-lg-6">
          <div class="form-check mb-3">
            <input id="toxic_chemical" class="form-check-input" type="checkbox" value="1"
              v-model="data._toxic_chemical" />
            <label class="form-check-label" for="toxic_chemical">
              สารพิษหรือสารเคมี
            </label>
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="col-12 col-lg-6">
          <div class="form-check mb-3">
            <input id="sharp_weapon" class="form-check-input" type="checkbox" value="1" v-model="data._sharp_weapon" />
            <label class="form-check-label" for="sharp_weapon">
              อาวุธ ของมีคม (ที่ไม่มีการแพ็กบรรจุภัณฑ์)
            </label>
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="col-12 col-lg-6">
          <div class="form-check mb-3">
            <input id="assemble" class="form-check-input" type="checkbox" value="1" v-model="data._assemble" />
            <label class="form-check-label" for="assemble">
              สินค้าต้องประกอบเอง
            </label>
          </div>
        </div>
        <!-- --------------------------- -->
      </div>
      <!-- ---------------- Delivery method --------------- -->
      <div class="row mb-3">

        <div class="col-6">
          <p>Delivery method</p>
          <hr />
          <!-- <div
            class="form-check mb-3"
            v-for="(e, i) in delivery_method"
            :key="i"
          >
            <input
              :id="e.key"
              class="form-check-input"
              type="radio"
              :value="e.key"
              v-model="data.delivery_method"
            />
            <label class="form-check-label" :for="e.key">
              {{ e.name }}
            </label>
          </div> -->

          <div class="form-check mb-3" v-for="(e, i) in delivery_method" :key="i">
            <input :id="e.key" class="form-check-input" type="checkbox" :value="e.key"
              v-model="data.delivery_method_list" />
            <label class="form-check-label" :for="e.key">
              {{ e.name }}
            </label>
          </div>
        </div>
        <div class="col-6">
          <p>Installation Service</p>
          <hr />
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" :checked="data.isInstall" disabled />
            <label class="form-check-label">มีบริการติดตั้ง</label>
          </div>
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" :checked="!data.isInstall" disabled />
            <label class="form-check-label">ไม่มีบริการติดตั้ง</label>
          </div>
        </div>
      </div>

      <!-- ---------------- warranty --------------- -->
      <div class="row mb-3">
        <div class="col-12">
          <p>Warranty</p>
          <hr />
          <div class="row mx-0 p-0 ">
            <div class="col-12 col-xl-6">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Warranty</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" min="0" max="360" v-model="data.warranty_w"
                    @keyup="checkLength('warranty_w')" />
                  <small class="form-text text-muted">0 - 360</small>
                </div>
              </div>
            </div>
            <!-- ------------------------------ -->

            <div class="col-12 col-xl-6">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Sub Warranty</label>
                <div class="col-sm-8">
                  <input type="number" class="form-control" min="0" max="360" v-model="data.sub_warranty"
                    @keyup="checkLength('sub_warranty')" />
                  <small class="form-text text-muted">0 - 360</small>
                </div>
              </div>
            </div>
            <!-- ------------------------------ -->

            <div class="col-12 col-xl-6">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Type Sub Warranty</label>
                <div class="col-sm-8">
                  <select class="form-control" v-model="data.type_sub_warranty">
                    <option value="">Select</option>
                    <option v-for="(e, i) in Type_Sub_Warranty_List" :key="i + 'type_list'" :value="e">{{ e }}</option>
                  </select>
                </div>
              </div>
            </div>
            <!-- ------------------------------ -->

            <div class="col-12 col-xl-6">
              <div class="form-group row">
                <label class="col-sm-4 col-form-label">Warranty Remark</label>
                <div class="col-sm-8">
                  <textarea v-model="data.warranty_Remark" class="form-control" maxlength="500" rows="3"></textarea>
                </div>
              </div>
            </div>
            <!-- ------------------------------ -->
          </div>
        </div>
      </div>
      <div class="py-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        _bulky: 0,
        _fragile: 0,
        _explosives_flammable: 0,
        _toxic_chemical: 0,
        _sharp_weapon: 0,
        _assemble: 0,
        delivery_method: "",
        delivery_method_list: [],
        warranty_w: "",
        sub_warranty: "",
        type_sub_warranty: "",
        warranty_Remark: "",
        isInstall: false
      },
      Type_Sub_Warranty_List: ["compressor", "heater", "motor", "default"],
      delivery_method: [
        { key: "3PL", name: "3PL" },
        { key: "EXP", name: "Express Delivery" },
        { key: "OWF", name: "Own Fleet" },
        { key: "CAC", name: "Click&Collect" },
      ],
    };
  },
  watch: {
    com_get_data: function (v) {
      this.get_data();
    },
    data: {
      async handler(val) {
        await this.$store.dispatch("enrichproductdetailActions/setother", val);
      },
      deep: true,
    },
  },
  computed: {
    com_get_data: function () {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    checkLength(key) {
      // console.log(this.data[`${key}`]);
      // if (Number(this.data[`${key}`]) < 0) {
      //   this.data[`${key}`] = 0;
      // } else if (Number(this.data[`${key}`]) > 360) {
      //   this.data[`${key}`] = 360;
      // }
    },

    async get_data() {
      // this.redata();
      let data = this.$store.getters["enrichproductdetailActions/get_data"];

      if (data !== null) {
        const {
          bulky,
          fragile,
          explosives_flammable,
          toxic_chemical,
          sharp_weapon,
          assemble,
          delivery_method,
          delivery_method_list,
          warranty_w,
          sub_warranty,
          type_sub_warranty,
          warranty_Remark,
          productIsInstall
        } = data;

        this.data = {
          _bulky: bulky == 1 ? true : false,
          _fragile: fragile == 1 ? true : false,
          _explosives_flammable: explosives_flammable == 1 ? true : false,
          _toxic_chemical: toxic_chemical == 1 ? true : false,
          _sharp_weapon: sharp_weapon == 1 ? true : false,
          _assemble: assemble == 1 ? true : false,
          delivery_method: delivery_method,
          delivery_method_list: delivery_method_list,

          warranty_w: warranty_w,
          sub_warranty: sub_warranty,
          type_sub_warranty: type_sub_warranty || "",
          warranty_Remark: warranty_Remark,
          isInstall: productIsInstall
        };
        // console.log(this.data);
      }
    },
  },
};
</script>

<style></style>
