<template>
  <b-modal id="Modalimg" size="xl" class="m-0 p-0" hide-header hide-footer>
    <div class="row m-0 p-0 justify-content-center">
      <div class="col-12 m-0 p-0 text-center">
        <img :src="b64" alt="" class="img-fluid m-0 p-0" />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modalimg",
  props: ["b64"],
  data() {
    return {};
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    hide() {
      this.$bvModal.hide("Modalimg");
    },
  },
};
</script>

<style></style>
