<template>
  <b-modal
    id="Modallinkfile"
    no-close-on-backdrop
    hide-header-close
    scrollable
    size="xl"
    button-size="sm"
    footer-bg-variant="access"
  >
    <template #modal-header="">
      <div class="row w-100">
        <div class="col-10">
          <h5>
            Link Product Files List
          </h5>
        </div>
        <div class="col-2 text-right">
          <span type="button" @click="hide()">
            x
          </span>
        </div>

        <!-- -------------------------- -->
        <div class="col-12 col-xl-6">
          <div class="input-group input-group-sm">
            <div class="input-group-prepend input-group-sm">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>

            <input
              type="search"
              class="form-control "
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn bt-main" @click="Search()">ค้นหา</button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- ------------------------------------- end header ------------------- -->
    <!-- ------------- add user --------------------- -->
    <div class="row">
      <div class="col-12  ">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(brand)="row">
            <span class="badge badge-main"> {{ row.item.brand }}</span>
          </template>
          <template #cell(data)="row">
            <!-- <p v-html="row.item"></p> -->
            <div class="row m-0 p-0 align-items-center">
              <div class="col-4 text-center">
                <!-- <img
                  v-if="checkimg(row.item.link)"
                  :src="$cons.API_IMG_UPLOAD + row.item.link"
                  class="img-thumbnail"
                  alt=""
                /> -->

                <v-lazy-image
                  v-if="checkimg(row.item.link)"
                  :src="$cons.API_IMG_UPLOAD + row.item.link"
                  :key="rekey"
                  class="img-thumbnail"
                />

                <embed
                  v-else
                  :src="$cons.API_IMG_UPLOAD + row.item.link"
                  type=""
                  class="img-fluid img  "
                />

                <!-- <img
                  v-else
                  src="@/assets/google-docs.png"
                  class="img-fluid img"
                  alt=""
                /> -->
              </div>
              <div class="col-8">
                <div class="input-group ">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    :value="$cons.API_IMG_UPLOAD + row.item.link"
                    disabled
                    :id="row.item.link"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-sm bt-main"
                      @click="copyURL(row.item.link)"
                    >
                      <span class="px-1" v-html="$svg_icon.link"></span>Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </div>
      <!-- ----------------------- -->
    </div>

    <template #modal-footer>
      <!-- ----------------------- -->
      <div class="col-12 font-0-8s my-1 m-0 p-0 ">
        <div class="row m-0 p-0 w-100 ">
          <div class="col-12  " v-if="modeadd == 'add'">
            <div class="row">
              <div class="col-12 col-xl-5">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-4 col-form-label text-right"
                    >รายละเอียด</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      maxlength="200"
                      v-model="data.remark"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 col-xl-5">
                <div class="form-group row">
                  <label
                    for="inputPassword"
                    class="col-sm-4 col-form-label text-right"
                    >เลือกไฟล์</label
                  >
                  <div class="col-sm-8">
                    <input
                      type="file"
                      class="  form-control-file "
                      accept=".jpg, .jpeg, .png, .pdf"
                      @change="upload($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="col-2 text-right">
                <button class="btn btn-sm  bt-bnb px-4" @click="confirm()">
                  เพิ่ม
                </button>
              </div>
            </div>
          </div>
          <!-- ----------------------- -->
          <div class="col-12 " v-else-if="modeadd == 'show'">
            <div class="row">
              <div class="col-10">
                <div class="input-group ">
                  <input
                    type="text"
                    class="form-control form-control-sm "
                    v-model="link"
                    disabled
                    id="myInput"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-sm bt-main"
                      @click="copyURLadd()"
                    >
                      <span class="px-1" v-html="$svg_icon.link"></span>Copy
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <button
                  type="button"
                  class="btn btn-sm bt-bnb"
                  @click="addnew()"
                >
                  เพิ่มใหม่
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import VLazyImage from "v-lazy-image/v2";
export default {
  name: "Modallinkfile",
  components: {
    VLazyImage,
  },
  data() {
    return {
      rekey: Date.now(),
      link: "",
      modeadd: "add",
      data: {
        dept: "",
        subDept: "",
        skuCode: "",
        brand: "",
        remark: "",
        link: "",
      },
      img: "",
      itemlist: [],
      items: [],
      mode: "remark",
      find: "",

      select_mode: [
        { name: "ค้นหา คำอธิบาย", key: "remark" },
        { name: "ค้นหา ชื่อไฟล์", key: "oldName" },
      ],
      fields: [
        { key: "No", label: "No." },
        { key: "catCode", label: "catCode" },
        { key: "brand", label: "Brand", class: "text-center" },
        { key: "oldName", label: "ชื่อไฟล์", thStyle: { width: "20%" } },
        { key: "remark", label: "คำอธิบาย", thStyle: { width: "20%" } },

        { key: "data", label: "", thStyle: { width: "50%" } },
      ],
    };
  },

  computed: {},
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  mounted() {
    this.redata();
    this.getpimlinkcontent();
  },
  methods: {
    checkimg(data) {
      let type = data.split(".")[data.split(".").length - 1].toUpperCase();
      if (type == "JPG" || type == "JPEG" || type == "PNG") {
        return true;
      } else {
        return false;
      }
    },
    async getpimlinkcontent() {
      try {
        let getdata = this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];
        if (getdata !== null) {
          const { skCode, catCode } = getdata;

          let data = { catProduct: catCode, catCode: "" };

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `contentFile/getpimlinkcontent`,
            data,
            1
          );

          this.items = getAPI.data;
          this.itemlist = getAPI.data;
          setTimeout(() => {
            this.rekey = Date.now();
          }, 100);
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    redata() {
      this.data = {
        dept: "",
        subDept: "",
        skuCode: "",
        brand: "",
        remark: "",
        link: "",
      };
    },
    unSearch() {
      this.items = this.itemlist;
    },
    addnew() {
      this.modeadd = "add";
      this.img = "";
    },
    Search() {
      // console.log(data);
      var results = [];
      if (this.find == "") {
        this.getremarkdetail();
      } else {
        let find = this.find;
        if (this.mode !== "showAll" && this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          // for (var key in objects[i]) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
          // }
        }
        this.items = results;
      }
    },
    copyURL(id) {
      /* Get the text field */
      var copyText = document.getElementById(`${id}`);

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      /* Alert the copied text */
      alert("Copied ");
    },

    async confirm() {
      try {
        if (!this.img) {
          throw new Error("กรุณาอัพโหลดไฟล์");
        }
        let getdata = this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];
        const { catCode, brand, skCode } = getdata;
        let check_old_file = await this.select_oldfile(this.img.name, catCode);

        if (check_old_file) {
          let data = new FormData();

          data.append("file", this.img);

          let saveimg = await this.$serviceAPI.call_API_FormData(
            "post",
            `upload`,
            data,
            0
          );

          const { path, originalname } = saveimg.data;
          let axdata = {
            link: path,
            Id: "",
            oldName: originalname,
            dept: String(catCode)
              .substring(0, 2)
              .trim(),
            subDept: String(catCode).trim(),
            skuCode: String(skCode),
            brand: String(brand).trim(),
            remark: this.data.remark,
          };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `contentFile/upload`,
            axdata,
            1
          );

          this.link = `${this.$cons.API_IMG_UPLOAD}${path}`;
          this.modeadd = "show";
          this.getpimlinkcontent();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async select_oldfile(name, catCode) {
      return new Promise(async (resolve, reject) => {
        this.find = name;
        this.mode = "oldName";
        this.Search();
        if (this.items.length == 0) {
          let confirm = await this.$serviceMain.showConfirmAlert(
            this,
            "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
          );

          if (confirm) {
            this.find = "";
            resolve(true);
          } else {
            resolve(false);
          }
        } else {
          let confirm = await this.$serviceMain.showConfirmAlert(
            this,
            `คุณมีไฟล์ ${name} ใน CATCODE : ${catCode} นี้เเล้ว ต้องการเพิ่มไหม? / You already have file ${name} in this CATCODE : ${catCode}, want to add it?`
          );

          if (confirm) {
            this.find = "";
            resolve(true);
          } else {
            resolve(false);
          }
        }
      });
    },
    async upload(event) {
      this.img = event.target.files[0];
    },
    copyURLadd() {
      /* Get the text field */
      var copyText = document.getElementById("myInput");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      /* Alert the copied text */
      alert("Copied ");
    },

    hide() {
      this.$bvModal.hide("Modallinkfile");
    },
  },
};
</script>

<style scoped>
.img {
  width: auto;
  height: 8vh;
}
</style>
