<template >
    <b-modal id="ModalReject" hide-footer no-close-on-backdrop size="lg">
        <template #modal-header="">
            <div class="row w-100">
                <div class="col-10">
                    <h4>
                        Reason for reject {{ propsData.type }}
                    </h4>
                </div>
                <div class="col-2 text-right">
                    <span type="button" @click="hide()">
                        X
                    </span>
                </div>
            </div>
        </template>
        <div class="row w-100 col-12 mx-1 my-1" v-if="listR.length > 0">
            <h5>ข้อมูลที่ขาด / ขอข้อมูลเพิ่มเติม </h5>
            <div class="row col-12">
                <div class="col-6" v-for="(e, i) in listR" :key="i">
                    <input class="form-check-input " type="checkbox" :id="i" :value="e.rejectName" v-model="selectedR" />
                    <label class="form-check-label " :for="i">{{
                        e.rejectName
                    }}</label>
                </div>
            </div>
        </div>
        <div class="row w-100 pt-3 col-12 mx-1 my-1" v-if="listN.length > 0">
            <h5>ข้อมูลไม่สมบูรณ์</h5>
            <div class="row col-12">
                <div class="col-6" v-for="(e, i) in listN" :key="i">
                    <input class="form-check-input " type="checkbox" :id="'N' + i" :value="e.rejectName"
                        v-model="selectedN" />
                    <label class="form-check-label " :for="'N' + i">{{
                        e.rejectName
                    }}</label>
                </div>
            </div>
        </div>
        <div class="row col-12 pt-3 mx-1 my-1" v-if="showRemark">
            <h5 class="mb-2">Remark </h5>
            <b-form-textarea id="textarea" v-model="remark" placeholder="Enter something..." rows="3"
                max-rows="6"></b-form-textarea>
        </div>
        <div class="col-12 col-lg-12 text-right  pt-4">
            <button type="button" class="btn bt-main mr-1" @click="save()">
                บันทึก
            </button>
            <button type="button" class="btn btn-danger ml-1" @click="hide()">
                ยกเลิก
            </button>
        </div>

    </b-modal>
</template>
<script>
export default {
    name: "ModalReject",
    props: ["propsData"],
    data() {
        return {
            listR: [],
            listN: [],
            selectedN: [],
            selectedR: [],
            remark: "",
            showRemark: false,

        }
    },
    computed: {

    },

    methods: {
        hide() {
            this.$bvModal.hide("ModalReject");
        },
        async getRejectCheckBoxList() {

            let getAPI = await this.$serviceAPI.call_API(
                "get",
                `product/getrejectlistmenu`,
                {},
                1
            );
            this.listR = getAPI.data.filter(
                (x) => x.role === this.propsData.type && x.rejectType === "R"
            );
            console.log(this.listR);
            this.listN = getAPI.data.filter(
                (x) => x.role === this.propsData.type && x.rejectType === "N"
            );
        },
        validate() {
            const msgRemark = "กรุณากรอกเหตุผลในการปฏิเสธ"
            if (this.propsData.type === "CW" || this.propsData.type === "GD") {
                if (this.selectedR.length === 0 && this.selectedN.length === 0) {
                    throw "กรุณาเลือกเหตุผล"
                }
                this.selectedR.forEach((item, index) => {
                    if (item === "อื่น ๆ" && this.remark === "") {
                        throw msgRemark
                    }
                })
            }
        },
        async save() {
            try {
                this.validate();
                await this.$store.dispatch("assigntasksActions/call_dataassign_again");
                let data = await this.$store.getters[
                    "assigntasksActions/get_dataassign"
                ];
                let get_data = await this.$store.getters[
                    "enrichproductdetailActions/get_data"
                ];
                const { assign_Id, enrich } = get_data;

                const arrayRemarkReject = this.selectedR;
                // arrayRemarkReject.push(this.selectedN)
                arrayRemarkReject.forEach((element, index) => {
                    if (element === "อื่น ๆ") {
                        arrayRemarkReject[index] = element + " " + this.remark
                    }
                });


                data.rejectType = String('R');
                if (this.propsData.type === "CW") {
                    data.senior_copyWr = await this.$store.getters[
                        "loginActions/GET_USER"
                    ].id;
                    data.copyWr_Creator_Status = "R";
                    data.senior_copyWr_Staus = "R";
                    data.remarkRejectCW = String(arrayRemarkReject);
                    data.role = String('CW');
                    if (this.selectedN.length > 0) {
                        data.rejectType = String('N');
                        data.Remark = String(this.selectedN);
                    }


                } else {
                    data.senior_graphicDsn = await this.$store.getters[
                        "loginActions/GET_USER"
                    ].id;
                    data.graphicDsn_Creator_Status = "R";
                    data.senior_graphicDsn_Status = "R";
                    data.remarkRejectGD = String(arrayRemarkReject);
                    data.role = String('GD');

                    if (this.selectedN.length > 0) {
                        data.rejectType = String('N');
                        data.Remark = String(this.selectedN);
                    }

                }
                data.approved_Date = new Date();

                let val = {
                    assign_Id: assign_Id,
                    skCode: data.skCode,
                    old_enrich: enrich,
                    new_enrich: "R",
                    update_By: this.$store.getters["loginActions/GET_USER"].id,
                    role: data.role,
                    rejectType: data.rejectType,
                    Remark: data.Remark
                };
                let _update_enrich = await this.$store.dispatch(
                    "enrichproductdetailActions/update_enrich",
                    val
                );
                //----- Update enrich -----
                if (_update_enrich !== "OK") {
                    // console.log(_update_enrich);
                    throw _update_enrich;
                }
                // console.log(data);
                let getAPI = await this.$serviceAPI.call_API(
                    "post",
                    `access/accessuser`,
                    data,
                    1
                );

                this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
                this.$store.dispatch(
                    "enrichproductdetailActions/getpimenrichproduct_data",
                    this.$route.params.skcode
                );
                this.hide();
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }
        },
    },
    async mounted() {
        if (this.propsData.type === "CW" || this.propsData.type === "GD") {
            this.getRejectCheckBoxList()
        }

    },
    watch: {
        selectedR: function () {
            this.showRemark = this.selectedR.includes("อื่น ๆ")
            if (this.showRemark === false) {
                this.remark = ""
            }
        },
    }

}
</script>
<style ></style>