<template>
  <div id="Productimages" class="row">
    <div class="col-12 p-5 shadow p-3 mb-3 bg-white rounded">
      <div class="row m-0 p-0">
        <div class="col-8">
          <p class="font-1-2s font-600">รูปภาพเเละวิดีโอ</p>
        </div>
        <div class="col-4 text-right"></div>
      </div>

      <div class=" text-right">
        <div
          class="btn-group"
          role="group"
          aria-label="Basic example"
          v-for="(e, i) in data.CatTree"
          :key="i"
        >
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="คลิกเพื่อดาวน์โหลด Image Guideline"
            type="button"
            class="btn btn-link btn-sm p-1"
            @click="getContentPdf(e)"
          >
            {{ String(e).split(":")[1] }}
          </button>
        </div>
      </div>
      <!-- ----------------------------- -->
      <div class="row">
        <div class="col-12 col-lg-3 text-right">
          <p>ภาพหลัก</p>
          <!-- <input
            type="file"
            accept="image/*"
            @change="requestUploadFilefilemain($event)"
            ref="filemain"
            style="display: none"
          /> -->
          <!-- <button
            type="button"
            class="btn btn-sm bt-main px-4"
            @click="$refs.filemain.click()"
          >
            <span v-html="$svg_icon.add"></span> เพิ่มภาพปก
          </button> -->
        </div>

        <div class="col-12 col-lg-9">
          <div class="row w-100 m-0 justify-content-between">
            <!-- --------------------- img main ----------------------- -->
            <div
              class="col-2 m-0 pl-0 pr-4"
              v-if="
                $store.getters['enrichproductdetailActions/get_data'] !==
                  null &&
                  $store.getters['enrichproductdetailActions/get_data']
                    .fileUploadsmain.length > 0
              "
            >
              <div
                class="p-1 rounded border position-relative box"
                :class="[
                  check_hw(
                    $store.getters['enrichproductdetailActions/get_data']
                      .fileUploadsmain[0].b64.size.h,
                    $store.getters['enrichproductdetailActions/get_data']
                      .fileUploadsmain[0].b64.size.w
                  )
                    ? 'good-border'
                    : 'fail-border',
                ]"
              >
                <!-- :key="data.fileUploadsmain[0].file.name" -->
                <img
                  :src="
                    $store.getters['enrichproductdetailActions/get_data']
                      .fileUploadsmain[0].b64.img
                  "
                  class="img-fluid box-img"
                  alt=""
                />
                <div class="action-img px-3 m-0">
                  <p class="m-0 p-0 d-flex justify-content-between">
                    <span
                      type="button"
                      v-html="$svg_icon.zoom"
                      @click="
                        zooming(
                          $store.getters['enrichproductdetailActions/get_data']
                            .fileUploadsmain[0].link ||
                            $store.getters[
                              'enrichproductdetailActions/get_data'
                            ].fileUploadsmain[0].b64.img
                        )
                      "
                    ></span>
                  </p>
                </div>
              </div>
              <p class="font-0-65s">
                <span
                  :class="[
                    check_hw(
                      $store.getters['enrichproductdetailActions/get_data']
                        .fileUploadsmain[0].b64.size.h,
                      $store.getters['enrichproductdetailActions/get_data']
                        .fileUploadsmain[0].b64.size.w
                    )
                      ? ''
                      : 'text-danger',
                  ]"
                  >{{
                    `(${$store.getters["enrichproductdetailActions/get_data"].fileUploadsmain[0].b64.size.w} x ${$store.getters["enrichproductdetailActions/get_data"].fileUploadsmain[0].b64.size.h})`
                  }}</span
                >
              </p>
            </div>
            <!-- ---------------- img mms ------------------- -->
            <div class="col-2 m-0  m-0 pl-0 pr-4" v-if="data.ImageMMS !== ''">
              <div class="m-0 p-0 border rounded">
                <img
                  :src="`data:image/jpeg;base64,${data.ImageMMS}`"
                  alt=""
                  class="img-fluid"
                />
              </div>

              <p class="font-0-8s  font-600 text-center">MMS Image</p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- ------------------------------------ -->
      <div class="row">
        <div class="col-12 col-lg-3 text-right">
          <p>ภาพประกอบ</p>
          <input
            type="file"
            accept="image/*"
            multiple
            @change="requestUploadFile($event)"
            ref="file"
            style="display: none"
          />

          <div class="row justify-content-end">
            <div
              id="droppable"
              type="button"
              class="col-10 border drag rounded px-0 py-5 m-0"
              :class="[active === true ? 'dragging' : '']"
              v-cloak
              @drop.prevent="dragFile($event)"
              @click="$refs.file.click()"
              @dragover.prevent="dragHover($event)"
              @dragleave.prevent="dragEnd($event)"
            >
              <p class="text-center m-0 p-0" v-html="$svg_icon.add"></p>
              <p class="font-0-7s   p-0 m-0 font-600 text-center">
                Drop file or click to upload
              </p>
            </div>
          </div>

          <!-- <button
            type="button"
            class="btn btn-sm bt-main px-4"
            @click="$refs.file.click()"
          >
            <span v-html="$svg_icon.add"></span> เพิ่มภาพสินค้า
          </button> -->
        </div>

        <div class="col-12 col-lg-9">
          <draggable
            v-model="data.fileUploads"
            class="row"
            :move="checkMove"
            @start="drag = true"
            @end="drag = false"
            @change="change"
          >
            <div
              class="col-2"
              type="button"
              v-for="(e, i) in data.fileUploads"
              :key="i"
            >
              <div
                class="w-100 m-0 p-1 mb-1 rounded border position-relative box"
                :class="[
                  check_hw(e.b64.size.h, e.b64.size.w)
                    ? 'good-border'
                    : 'fail-border',
                ]"
              >
                <img :src="e.save?e.b64.img:e.link" class="img-fluid box-img" alt="" />
                <div class="action-img px-3 m-0">
                  <p class="m-0 p-0 d-flex justify-content-between">
                    <span
                      type="button"
                      v-html="$svg_icon.zoom"
                      @click="zooming(e.link || e.b64.img)"
                    ></span>
                    <span
                      type="button"
                      v-html="$svg_icon.removesm"
                      @click="remove(e)"
                    ></span>
                  </p>
                </div>
              </div>
              <p class="font-0-65s">
                {{ e.file.name }}
                <span
                  :class="[
                    check_hw(e.b64.size.h, e.b64.size.w) ? '' : 'text-danger',
                  ]"
                  >{{ `(${e.b64.size.w} x ${e.b64.size.h})` }}</span
                >
              </p>
            </div>
          </draggable>
        </div>
      </div>
      <hr />
      <!-- ------------------------------------ -->
      <div class="row">
        <div class="col-12 col-lg-3 text-right">
          <p>วิดีโอสินค้า</p>
          <div class="input-group mb-2 input-group-sm">
            <input
              type="text"
              class="form-control"
              placeholder="ลิงค์วีดีโอ youtube"
              v-model="LinkVideo"
              :disabled="data.video.length == 2"
            />
          </div>
          <button
            type="button"
            class="btn btn-sm bt-main px-4"
            @click="addvideo()"
            :disabled="data.video.length == 2"
          >
            <span v-html="$svg_icon.add"></span> เพิ่มวิดีโอสินค้า
          </button>
        </div>
        <div class="col-12 col-lg-9">
          <div class="row">
            <div
              class="col-3"
              type="button"
              v-for="(e, i) in data.video"
              :key="i"
            >
              <div class="w-100 m-0 mb-1 rounded border position-relative box">
                <iframe
                  class="video embed-responsive-item rounded"
                  :src="e.link"
                ></iframe>
                <div class="action-vdo px-3 m-0">
                  <p class="text-right m-0 p-0">
                    <span
                      type="button"
                      v-html="$svg_icon.removesm"
                      @click="removideo(e)"
                    ></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- ------------------------------------ -->
      <div class="row">
        <div class="col-12 col-lg-3 text-right">
          <p>ภาพสินค้า 360</p>
        </div>
        <div class="col-12 col-lg-9">
          <div class="row">
            <div class="col-3">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  value="1"
                  v-model="data.img360_show"
                />
                <label class="form-check-label">เเสดง</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  value="0"
                  v-model="data.img360_show"
                />
                <label class="form-check-label">ไม่เเสดง</label>
              </div>
            </div>
            <div class="col-9">
              <div class="form-group">
                <input
                  type="text"
                  v-model="data.img360_link"
                  disabled
                  class="form-control"
                />
              </div>
            </div>

            <!-- <div class="col-6 col-xl-3">
              <vue-three-sixty
                :loop="1"
                boxShadow
                autoplay
                :amount="36"
                imagePath="https://scaleflex.cloudimg.io/width/600/q35/https://scaleflex.ultrafast.io/https://scaleflex.airstore.io/demo/chair-360-36"
                fileName="chair_{index}.jpg?v1"
              >
              </vue-three-sixty>
            </div> -->
          </div>
          <div class="row justify-content-center" v-if="data.img360_show == 1">
            <div class="col-6 text-center">
              <Img630 :sku360="data.img360_link" />
              <p class="text-muted mt-2">เเสดงตัวอย่างรูปภาพ 360°</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modalimg :key="reModalimg" :b64="dataModalimg" />
  </div>
</template>

<script>
import Modalimg from "@/components/Products/EnrichProductDetail/Modalimg.vue";
import draggable from "vuedraggable";
import Img630 from "@/components/common/Img630.vue";
export default {
  components: {
    draggable,
    Modalimg,
    Img630,
  },
  data() {
    return {
      active: false,
      reModalimg: Date.now(),
      re_img_main: Date.now() + 20,
      dataModalimg: "",
      fileUploads: null,
      LinkVideo: "",
      can_update: false,
      enrich: "",
      b64: "",
      data: {
        linkForVdo: "",
        images: "",
        fileUploadsmain: [],
        fileUploads: [],
        img360_show: 0,
        img360_link: "",
        video: [],
        ImageMMS: "",
      },
    };
  },
  watch: {
    "data.img360_show": function() {
      if (this.data.img360_show == 1) {
        this.data.img360_link = `images360/${this.$route.params.skcode}`;
      } else {
        this.data.img360_link = "";
      }
    },
    com_get_data: function(v) {
      this.get_data();
    },

    data: {
      async handler(val) {
        await this.$store.dispatch(
          "enrichproductdetailActions/setimagevideo",
          val
        );
      },
      deep: true,
    },
  },

  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
  },

  created() {},

  async mounted() {
    await this.get_data();
    // ----------- droppable ---------------------
    // const ele = document.getElementById("droppable");
    // console.log(ele);
    // ele.addEventListener("dragenter", function(e) {
    //   e.preventDefault();
    //   e.target.classList.add("dragging");
    // });
    // ele.addEventListener("drop", function(e) {
    //   e.preventDefault();
    //   e.target.classList.remove("dragging");
    // });
    // ----------- droppable ---------------------
  },

  methods: {
    clear() {
      this.$refs.file.value = null;
    },
    async dissable_update() {
      let get_data = await this.$store.getters[
        "enrichproductdetailActions/get_data"
      ];

      const { enrich } = get_data;
      let _enrich = String(this.$serviceMain.chechnull(enrich)).trim();

      if (_enrich !== "" && _enrich !== "I") {
        this.can_update = true;
      } else {
        this.can_update = false;
      }
    },

    redata() {
      this.data = {
        linkForVdo: "",
        images: "",
        fileUploadsmain: [],
        fileUploads: [],
        img360_show: false,
        img360_link: "",
        video: [],
        ImageMMS: "",
        CatTree: { cat1: "", cat2: "", cat3: "" },
      };
    },
    async get_data() {
      // this.redata();

      let data = await this.$store.getters[
        "enrichproductdetailActions/get_data"
      ];

      if (data !== null) {
        const {
          linkForVdo,
          images,
          ImageForFeed,
          LinkforVDO2,
          fileUploadsmain,
          fileUploads,
          video,
          CAT1,
          CAT2,
          CAT3,
          img360_show,
          ImageMMS,
        } = data;

        this.data = {
          linkForVdo: linkForVdo,
          LinkforVDO2: LinkforVDO2,
          images: images,
          fileUploadsmain: fileUploadsmain,
          fileUploads: fileUploads,
          img360_show: img360_show,
          img360_link: "",
          video: video,
          ImageMMS: this.$serviceMain.chechnull(ImageMMS),
          CatTree: { cat1: CAT1, cat2: CAT2, cat3: CAT3 },
        };

        // this.dissable_update();
        // this.re_img_main = Date.now();
        // if (linkForVdo !== null && linkForVdo !== "") {
        //   let _data = {
        //     index: this.data.video.length,
        //     link: await this.getvideo(linkForVdo),
        //     fixed: false,
        //   };
        //   this.data.video.push(_data);
        // }
        // if (LinkforVDO2 !== null && LinkforVDO2 !== "") {
        //   let _data = {
        //     index: this.data.video.length,
        //     link: await this.getvideo(LinkforVDO2),
        //     fixed: false,
        //   };
        //   this.data.video.push(_data);
        // }
      }
    },
    async setfileUploads(data) {
      let datatrim;
      let _trim = [];
      if (data !== "") {
        datatrim = data.split("|");
        for await (var [i, v] of datatrim.entries()) {
          if (v !== "") {
            let link = `${this.$cons.API_URL_IMG}${v}`;

            let name = v.split("/")[v.split("/").length - 1].split(".")[0];
            let data = {
              file: { file: link, name: name },
              b64: await this.convert_imgbase64url(link),
              fixed: false,
              index: this.data.fileUploads.length,
            };
            _trim.push(data);
          }
        }
      }
      // console.log(datatrim);

      return _trim;
      // for await ( var [] ){}
    },
    // ------------------------- move img------------------------
    remove(e) {
      let index = this.data.fileUploads.findIndex((i) => i == e);
      if (index != -1) {
        if (confirm(`Do you really want to delete ${e.file.name}?`)) {
          this.data.fileUploads.splice(index, 1);
          this.change();
        }
      }
    },
    change() {
      for (var i in this.data.fileUploads) {
        this.data.fileUploads[i].index = i;
      }
    },
    checkMove(e) {
      return this.isDraggable(e.draggedContext);
    },
    isDraggable(context) {
      const { index, futureIndex } = context;
      return !(
        this.data.fileUploads[index].fixed ||
        this.data.fileUploads[futureIndex].fixed
      );
    },
    setfixed(e) {
      let index = this.data.fileUploads.findIndex((i) => i == e);
      if (index != -1) {
        this.data.fileUploads[index].fixed = !this.data.fileUploads[index]
          .fixed;
      }
    },
    // ------------------------- end move img -------------------
    zooming(img) {
      // console.log(img);
      this.reModalimg = Date.now();
      this.dataModalimg = img;
      setTimeout(() => {
        this.$bvModal.show("Modalimg");
      }, 100);
    },
    check_hw(h, w) {
      if (Number(h) == 1200 && Number(w) == 1200) {
        return true;
      } else {
        return false;
      }
    },
    async requestUploadFilefilemain(e) {
      this.data.fileUploadsmain = [];
      this.data.fileUploadsmain.push({
        file: e.target.files[0],
        b64: await this.convert_imgbase64(e.target.files[0]),
      });
    },
    // async requestUploadFile(e) {
    //   let files = e.target.files;

    //   for (let i in files) {
    //     if (i !== "item" && i !== "length") {
    //       this.data.fileUploads.push({
    //         file: files[i],
    //         name: files[i].name,
    //         b64: await this.convert_imgbase64(files[i]),
    //         fixed: false,
    //         index: this.data.fileUploads.length,
    //         save: true,
    //       });
    //     }
    //   }
    //   // console.log(this.data.fileUploads);
    //   this.clear();
    // },
    // async dragFile(e) {
    //   // console.log(e.dataTransfer.files);
    //   let files = e.dataTransfer.files;

    //   var filenames = [];
    //   Object.keys(files).forEach(function(key, index) {
    //     filenames.push(files[index].name);
    //   });

    //   for await (let e of filenames.sort()) {
    //     let check = Object.values(files).find((obj) => {
    //       return String(obj.name).trim() == e;
    //     });
    //     // console.log(check);
    //     if (check !== undefined) {
    //       let { type, name } = check;
    //       let check_img = type.split("/")[0];

    //       if (check_img == "image") {
    //         this.data.fileUploads.push({
    //           file: check,
    //           name: name,
    //           b64: await this.convert_imgbase64(check),
    //           fixed: false,
    //           index: this.data.fileUploads.length,
    //           save: true,
    //         });
    //       }
    //     }
    //   }
    //   // for (let i of files) {
    //   //   let { type, name } = i;

    //   //   let check_img = type.split("/")[0];

    //   //   if (check_img == "image") {
    //   //     this.data.fileUploads.push({
    //   //       file: i,
    //   //       name: name,
    //   //       b64: await this.convert_imgbase64(i),
    //   //       fixed: false,
    //   //       index: this.data.fileUploads.length,
    //   //       save: true,
    //   //     });
    //   //   }
    //   // }
    //   this.clear();
    //   this.active = false;
    // },
    async requestUploadFile(e) {
      try {
        let files = e.target.files;

        for (let i in files) {
          if (i !== "item" && i !== "length") {
            let type = String(
              files[i].type.split(`image/`)[
                files[i].type.split(`image/`).length - 1
              ]
            )
              .toLocaleUpperCase()
              .trim();

            if (type !== "PNG" && type !== "JPG" && type !== "JPEG") {
              throw `Support .png, .jpg, .jpeg only`;
            }

            this.data.fileUploads.push({
              file: files[i],
              name: files[i].name,
              b64: await this.convert_imgbase64(files[i]),
              fixed: false,
              index: this.data.fileUploads.length,
              save: true,
            });
          }
        }
        // console.log(this.data.fileUploads);
        this.clear();
      } catch (error) {
        this.clear();

        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async dragFile(e) {
      try {
        // console.log(e.dataTransfer.files);
        let files = e.dataTransfer.files;

        var filenames = [];
        Object.keys(files).forEach(function(key, index) {
          filenames.push(files[index].name);

          let type = String(
            files[index].type.split(`image/`)[
              files[index].type.split(`image/`).length - 1
            ]
          )
            .toLocaleUpperCase()
            .trim();

          if (type !== "PNG" && type !== "JPG" && type !== "JPEG") {
            throw `Support .png, .jpg, .jpeg only`;
          }
        });

        for await (let e of filenames.sort()) {
          let check = Object.values(files).find((obj) => {
            return String(obj.name).trim() == e;
          });
          // console.log(check);
          if (check !== undefined) {
            let { type, name } = check;
            let check_img = type.split("/")[0];

            if (check_img == "image") {
              this.data.fileUploads.push({
                file: check,
                name: name,
                b64: await this.convert_imgbase64(check),
                fixed: false,
                index: this.data.fileUploads.length,
                save: true,
              });
            }
          }
        }
  
        this.clear();
        this.active = false;
      } catch (error) {
        this.clear();
        this.active = false;
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    dragHover(e) {
      // e.target.classList.add("dragging");
      this.active = true;
    },
    dragEnd(e) {
      // e.target.classList.remove("dragging");
      setTimeout(() => {
        this.active = false;
      }, 100);
    },
    // async getvideo(link) {
    //   let url = `https://www.youtube.com/embed/${
    //     link.split("/")[link.split("/").length - 1]
    //   }`;

    //   return url;
    // },
    addvideo() {
      try {
        if (this.LinkVideo == "") {
          throw "กรุณาระบุ Link วิดีโอ";
        }
        // let position = this.LinkVideo.search("watch?v");
        var url;
        url = `https://www.youtube.com/embed/${this.$serviceMain.youtube(
          this.LinkVideo
        )}`;
        // if (position !== -1) {
        //   url = `https://www.youtube.com/embed/${
        //     this.LinkVideo.split("v=")[1].split("&")[0]
        //   }`;
        // } else {
        //   url = `https://www.youtube.com/embed/${
        //     this.LinkVideo.split("/")[this.LinkVideo.split("/").length - 1]
        //   }`;
        // }
        let data = {
          index: this.data.video.length,
          link: url,
          fixed: false,
        };

        this.data.video.push(data);
        this.LinkVideo = "";
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    removideo(e) {
      let index = this.data.video.findIndex((i) => i == e);
      if (index != -1) {
        if (confirm(`Do you really want to delete ${e.link}?`)) {
          this.data.video.splice(index, 1);
        }
      }
    },
    // async convert_imgbase64url(file) {
    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       let getAPI = await this.$serviceAPI.call_API(
    //         "post",
    //         `imgb64/getimgb64url`,
    //         { url: file },
    //         1
    //       );

    //       var image = new Image();
    //       image.src = getAPI.data;
    //       image.onload = function () {
    //         var height = this.height;
    //         var width = this.width;

    //         resolve({ img: getAPI.data, size: { h: height, w: width } });
    //       };
    //     } catch (error) {
    //       reject(error);
    //     }
    //   });
    // },
    convert_imgbase64(file) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var image = new Image();
          image.src = reader.result;
          image.onload = function() {
            var height = this.height;
            var width = this.width;
            resolve({ img: reader.result, size: { h: height, w: width } });
          };
        };
        reader.onerror = (error) => reject(error);
      });
    },
    removefile(i) {
      this.data.fileUploads.splice(i, 1);
    },
    async getContentPdf(value) {
      try {
        var catCode = String(value).split(":")[0];
        var catName = String(value).split(":")[1];
        var data = {};
        let getData = await this.$serviceAPI.call_API_Getfile(
          "get",
          `product/getimgguideline/${catCode}`,
          data,
          1
        );
        let blob = new Blob([getData.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          `ขออภัยไม่พบ Image Guildline ${catCode} ${catName.replace(
            />>/g,
            ""
          )} `
        );
      }
    },
    set_script() {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "../../../assets/orbitvu12/orbitvu.js?v=16"
      );
      document.head.appendChild(recaptchaScript);
    },
  },
};
</script>

<style scoped>
.box-img {
  width: 100%;
  height: auto;
}
.video {
  width: 100%;
  height: 20vh;
}
.box .action-img {
  position: absolute;
  bottom: 0px;
  left: 1px;
  right: 1px;
  width: 100%;
  height: 28%;
  color: transparent;
  background: transparent;
  z-index: -99;
}
.box:hover .action-img {
  position: absolute;
  bottom: 0px;
  left: 1px;
  right: 1px;
  width: 100%;
  height: 28%;
  color: white;
  background: hsla(0, 1%, 17%, 0.5);
  z-index: 1;
}
.box .action-vdo {
  position: absolute;
  top: 0px;
  left: 1px;
  right: 1px;
  width: 100%;
  height: 20%;
  color: transparent;
  background: transparent;
  z-index: -99;
}
.box:hover .action-vdo {
  position: absolute;
  top: 0px;
  left: 1px;
  right: 1px;
  width: 100%;
  height: 20%;
  color: white;
  background: hsla(0, 0%, 100%, 0.5);
  z-index: 1;
}
.img-360 {
  height: 10vh;
  width: auto;
}
</style>
