<template>
  <div id="Boxdetail" class="container-fluid mx-0 p-0 ">
    <div class="row ">
      <div class="col-12 col-lg-9 px-4 py-3  shadow  mb-4 bg-white rounded  ">
        <div class="row">
          <div class="col-10">
            <p class=" ">
              <span class="font-600 ">ชื่อสินค้า : </span
              >{{ `${data.skCode} - ${data.prNameTH}` }}
            </p>
            <p class=" ">
              <span class="font-600 ">CatCode : </span>{{ data.catCode }}
            </p>
          </div>
          <div class="col-2 font-0-7s ">
            <p class="text-muted text-right m-0 p-0">
              อัพเดทล่าสุด {{ data.lastUpdate }}
            </p>
            <p class="text-muted text-right m-0 p-0">
              โดย {{ data.updateByUser }}
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">Brand : </span>{{ data.brand }}
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">Barcode : </span>{{ data.prCode }}
            </p>
          </div>
          <!-- ---------------- -->

          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">Product Status : </span
              ><span
                :class="
                  data.status == 'Y' ? 'badge badge-main' : 'badge badge-twd'
                "
                >{{ data.status }}</span
              >
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">Special Attribute : </span
              ><span>{{ data.pratbcode }}</span>
            </p>
          </div>
          <!-- ---------------- -->

          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">ชื่อเต็ม หน่วยขาย : </span
              ><span>{{ data.uomEN }}</span>
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">รหัสสถานภาพของ SKU : </span
              ><span>{{ data.prskustatus }}</span>
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">เหตุผลในการเปลี่ยนสถานภาพ : </span
              ><span>{{ data.reason }}</span>
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">ชื่อ Home/Branch : </span
              ><span>{{ data.homexbrch }}</span>
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">ขายบนเว็บ : </span
              >
              <span>Y</span>
              <!-- <span>{{ data.send2col }}</span> -->
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">รับประกัน : </span
              ><span>{{ data.warranty }}</span>
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">วันที่ลงทะเบียน​ : </span
              ><span>{{ data.createdate }}</span>
            </p>
          </div>
          <!-- ---------------- -->
          <div class="col-12 col-lg-4">
            <p class=" font-0-8s">
              <span class="font-600 ">วันที่รับสินค้าครั้งแรก : </span
              ><span>{{ data.frdate }}</span>
            </p>
          </div>
          <!-- ---------------- -->
          <!-- <div class="col-12 col-lg-6">
            <p class=" font-0-8s">
              <span class="font-600 ">Icon Tag : </span>{{ data.iconTag }}
            </p>
          </div> -->
        </div>
      </div>
      <div class="col-12 col-lg-3 mb-4  px-1 px-lg-2">
        <div class="m-0 p-0 w-100 px-3 py-3   shadow bg-white rounded h-100 ">
          <div class="row">
            <!-- ---------------- -->
            <div class="col-12">
              <p class=" font-0-8s">
                <span class="font-600 ">Enrich : </span
                ><span
                  v-if="data.enrich !== ''"
                  class="font-1-0s"
                  :class="{
                    'badge badge-main': data.enrich == 'Y',
                    'badge badge-bnb': data.enrich == 'A',
                    'badge badge-twd':
                      data.enrich !== 'A' && data.enrich !== 'Y',
                  }"
                  >{{ data.enrich }} - {{ data.enrichName }}</span
                >
              </p>
            </div>
            <!-- ---------------- -->
            <div class="col-12 ">
              <p class=" font-0-8s">
                <span class="font-600 ">Approve By : </span
                ><span class="  text-main font-600 ">{{ data.Approveby }}</span>
              </p>
            </div>
            <!-- ---------------- -->
            <div class="col-12 ">
              <p class=" font-0-8s">
                <span class="font-600 ">Approve Date : </span
                ><span class=" text-main font-600 ">{{
                  data.Approvedate
                }}</span>
              </p>
            </div>

            <!-- ---------------- -->
            <div class="col-12 col-lg-6">
              <p class=" font-0-8s">
                <span class="font-600 ">PR Weight: </span>{{ data.prweight }}
              </p>
            </div>
            <!-- ---------------- -->
            <div class="col-12 col-lg-6">
              <p class=" font-0-8s">
                <span class="font-600 ">PR Height : </span>{{ data.prheight }}
              </p>
            </div>
            <!-- ---------------- -->
            <div class="col-12 col-lg-6">
              <p class=" font-0-8s">
                <span class="font-600 ">PR Width : </span>{{ data.prwidth }}
              </p>
            </div>
            <!-- ---------------- -->
            <div class="col-12 col-lg-6">
              <p class=" font-0-8s">
                <span class="font-600 ">PR length : </span>{{ data.prlength }}
              </p>
            </div>
            <!-- ---------------- -->
            <div class="col-12 col-lg-6">
              <p class=" font-0-8s">
                <span class="font-600 ">BX Weight: </span>{{ data.bweight }}
              </p>
            </div>
            <!-- ---------------- -->
            <div class="col-12 col-lg-6">
              <p class=" font-0-8s">
                <span class="font-600 ">BX Height : </span>{{ data.bheight }}
              </p>
            </div>
            <!-- ---------------- -->
            <div class="col-12 col-lg-6">
              <p class=" font-0-8s">
                <span class="font-600 ">BX Width : </span>{{ data.bwidth }}
              </p>
            </div>
            <!-- ---------------- -->
            <div class="col-12 col-lg-6">
              <p class=" font-0-8s">
                <span class="font-600 ">BX Length : </span>{{ data.blength }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Boxdetail",
  data() {
    return {
      data: {
        catCode: "",
        enrich: "",
        ableToPayCC: "",
        brand: "",
        flag: "",
        iconTag: "",
        skCode: "",
        prNameTH: "",
        prCode: "",
        updateBy: "",
        lastUpdate: "",
        status: "",
        deliveryPossible: "",
        productDiffPrice: "",
        vatable: "",
        uomTH: "",
        uomEN: "",
        prweight: "",
        prheight: "",
        prwidth: "",
        prlength: "",
        bweight: "",
        bheight: "",
        bwidth: "",
        blength: "",
        Updatelast: "",
        Approveby: "",
        Approvedate: "",
        pratbcode: "",
        prskustatus: "",
        reason: "",
        homexbrch: "",
        send2col: "",
        warranty: "",
        createdate: "",
        frdate: "",
        enrichName: "",
        updateByUser: "",
      },
    };
  },
  watch: {
    com_get_data: function(v) {
      this.get_data();
    },
  },
  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    async get_data() {
      // this.redata();
      let data = this.$store.getters["enrichproductdetailActions/get_data"];

      if (data !== null) {
        const {
          catCode,
          enrich,
          ableToPayCC,
          brand,
          flag,
          iconTag,
          skCode,
          prNameTH,
          prCode,
          updateBy,
          lastUpdate,
          status,
          deliveryPossible,
          productDiffPrice,
          vatable,
          uomTH,
          uomEN,
          PR_WEIGHT,
          PR_HEIGHT,
          PR_WIDTH,
          PR_LENGTH,
          BOX_WEIGHT,
          BOX_HEIGHT,
          BOX_WIDTH,
          BOX_LENGTH,
          Updatelast,
          pratbcode,
          prskustatus,
          reason,
          homexbrch,
          send2col,
          warranty,
          createdate,
          frdate,
          enrichName,
          updateByUser,
        } = data;

        this.data = {
          catCode: String(catCode).trim(),
          enrich: String(enrich).trim(),
          ableToPayCC: String(ableToPayCC).trim(),
          brand: String(brand).trim(),
          flag: String(flag).trim(),
          iconTag: String(iconTag).trim(),
          skCode: String(skCode).trim(),
          prNameTH: String(prNameTH).trim(),
          prCode: String(prCode).trim(),
          updateBy: String(updateBy).trim(),
          updateByUser: String(updateByUser).trim(),
          lastUpdate: String(Updatelast).trim(),
          status: String(status).trim(),
          deliveryPossible: String(deliveryPossible).trim(),
          productDiffPrice: String(productDiffPrice).trim(),
          vatable: String(vatable).trim(),
          uomTH: String(uomTH).trim(),
          uomEN: String(uomEN).trim(),
          prweight: PR_WEIGHT,
          prheight: PR_HEIGHT,
          prwidth: PR_WIDTH,
          prlength: PR_LENGTH,
          bweight: BOX_WEIGHT,
          bheight: BOX_HEIGHT,
          bwidth: BOX_WIDTH,
          blength: BOX_LENGTH,
          Updatelast: String(Updatelast).trim(),
          pratbcode: String(pratbcode).trim(),
          prskustatus: String(prskustatus).trim(),
          reason: String(reason).trim(),
          homexbrch: String(homexbrch).trim(),
          send2col: String(send2col).trim(),
          warranty: String(warranty).trim(),
          createdate: createdate,
          frdate: frdate,
          enrichName: String(enrichName).trim(),
        };
      }
    },
  },
};
</script>

<style scoped>
.myborder {
  border: 2px solid #515050;
  border-radius: 0.5rem;
}
.myfont {
  font-size: 0.75em;
}
.text-b {
  font-weight: 600;
}
</style>
