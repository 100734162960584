<template>
  <div class="row w-100 m-0 p-0">
    <div class="col-12 m-0 px-3">
      <!-- ----------------------------- -->

      <div class="bg-access p-2  mb-2 w-100  font-0-8s">
        <div class="row p-1">
          <div class="col-12">
            <p class="font-600 m-0 p-0">VENDOR</p>
          </div>

          <div class="col-12 col-xl-6">
            ติดต่อ :<span class="ml-2">{{ data.vendorContact }}</span>
          </div>
          <div class="col-12 col-xl-6">
            โทร :<span class="ml-2">{{ data.vendorPhone }}</span>
          </div>
          <div class="col-12 col-xl-6">
            ชื่อ/ร้านค้า :<span class="ml-2">{{ data.vendorName }}</span>
          </div>
          <div class="col-12 col-xl-6">
            เว็บไซต์ :<span class="ml-2">{{ data.vendorWeb }}</span
            ><span
              v-if="data.vendorWeb !== ''"
              class="ml-2 text-main"
              type="button"
              v-html="$svg_icon.Chrome"
              @click="view(data.vendorWeb)"
            ></span>
          </div>
        </div>

        <!-- ------------------------ -->
        <div class="row mt-2 mb-2">
          <div class="col-6 col-xl-2 text-right">อีเมลล์ผู้รับผิดชอบ</div>
          <div class="col-6 col-xl-10">
            <input
              class="form-control form-control-sm"
              type="text"
              disabled
              v-model="data.sendToEmail"
            />
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="row mb-2">
          <div class="col-6 col-xl-2 text-right">ส่งถึงอีเมลล์</div>
          <div class="col-6 col-xl-10">
            <input
              class="form-control form-control-sm"
              type="text"
              disabled
              v-model="data.vendorEmail"
            />
          </div>
        </div>
        <!-- --------------------------- -->
      </div>
      <!-- ------------------- -->

      <!-- <div class="row mb-2">
        <div class="col-6 col-xl-2 text-right">สำเนาถึงอีเมลล์</div>
        <div class="col-6 col-xl-10">
          <input
            class="form-control form-control-sm"
            type="text"
            disabled
            v-model="data.cc_email"
          />
        </div>
      </div> -->
    </div>
    <div class="col-12 m-0 p-0">
      <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
        <!-- ---------------------------------------------1----------------------------------------- -->
        <b-tab title="QC Copy Writer" :title-link-class="linkClass(0)" active>
          <div class="row">
            <div class="col-12  my-0">
              <div class="row">
                <!-- ----------------------- -->
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-sm-4 ">ข้อมูลครบเเล้ว </label>
                    <div class="col-sm-8">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="N"
                          v-model="data.missing_Data_Done"
                          :disabled="
                            !$roleaction('Req Missing Data') ||
                              data.copyWr_Qc_Status == 'Y' ||
                              check_enrich()
                          "
                        />
                        <label class="form-check-label">NO</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="Y"
                          v-model="data.missing_Data_Done"
                          :disabled="
                            !$roleaction('Req Missing Data') ||
                              data.copyWr_Qc_Status == 'Y' ||
                              check_enrich()
                          "
                        />
                        <label class="form-check-label">YES</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ---------------------------- -->
              </div>
            </div>
            <div class="col-12 ">
              <h5>
                ข้อมูลที่ขาด / ขอข้อมูลเพิ่ม
              </h5>
            </div>
            <div class="col-6" v-for="(e, i) in missing_Data" :key="i">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :disabled="
                    !$roleaction('Req Missing Data') ||
                      data.missing_Data_Done == 'Y' ||
                      data.copyWr_Qc_Status == 'Y' ||
                      check_enrich()
                  "
                  :value="e"
                  :id="e"
                  v-model="data.select_missing_Data"
                />
                <label class="form-check-label" :for="e">
                  {{ `${e !== "OTHER" ? e : "อื่นๆ..."}` }}
                </label>
                <textarea
                  v-if="e == 'OTHER' && selectother_missing_data"
                  class="form-control"
                  type="text"
                  placeholder="อื่นๆ..."
                  v-model="data.other_missing_data"
                  maxlength="600"
                />
              </div>
            </div>

            <div class="col-12 mt-2">
              <div class="">
                <label>Remark</label>
                <textarea
                  :disabled="
                    !$roleaction('Req Missing Data') ||
                      data.copyWr_Qc_Status == 'Y' ||
                      check_enrich()
                  "
                  class="form-control "
                  v-model="data.remark"
                ></textarea>
              </div>
            </div>
            <p
              class="font-0-7s px-3 pt-3"
              v-if="
                $serviceMain.split_name(
                  $store.getters['assigntasksActions/get_dataassign'].nameQcWr
                ) !== ''
              "
            >
              Update By :
              {{
                $serviceMain.split_name(
                  $store.getters["assigntasksActions/get_dataassign"].nameQcWr
                )
              }}
            </p>
            <div class="col-12 text-right mt-3">
              <button
                type="button"
                :disabled="
                  !$roleaction('Req Missing Data') ||
                    data.copyWr_Qc_Status == 'Y'
                "
                class="btn bt-main px-4"
                @click="confirm_missdata()"
              >
                บันทึก
              </button>
            </div>
          </div>
        </b-tab>
        <!-- ---------------------------------------------------------------2---------------------------- -->
        <b-tab title="QC Graphic Design" :title-link-class="linkClass(1)">
          <div class="row">
            <div class="col-12   my-0">
              <div class="row">
                <!-- ----------------------- -->
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-sm-4 ">ข้อมูลครบเเล้ว </label>
                    <div class="col-sm-8">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="N"
                          v-model="data.req_Image_Done"
                          :disabled="
                            !$roleaction('Req Image') ||
                              data.graphicDsn_Qc_Status == 'Y' ||
                              check_enrich()
                          "
                        />
                        <label class="form-check-label">NO</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          value="Y"
                          v-model="data.req_Image_Done"
                          :disabled="
                            !$roleaction('Req Image') ||
                              data.graphicDsn_Qc_Status == 'Y' ||
                              check_enrich()
                          "
                        />
                        <label class="form-check-label">YES</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ---------------------------- -->
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-sm-4 col-form-label text-right"
                      >จำนวนมุมภาพที่มี</label
                    >
                    <div class="col-sm-8">
                      <select
                        class="form-control form-control-sm"
                        v-model="data.qty_Corners"
                        :disabled="
                          !$roleaction('Req Image') ||
                            data.graphicDsn_Qc_Status == 'Y' ||
                            check_enrich()
                        "
                      >
                        <option value="">จำนวนมุมภาพที่มี...</option>
                        <option
                          v-for="(e, i) in qty_Corners"
                          :key="i"
                          :value="e"
                          >{{ e }}</option
                        >
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 ">
              <h5>
                ภาพที่ขอเพิ่ม
              </h5>
            </div>
            <div class="col-6" v-for="(e, i) in req_Image" :key="i">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :disabled="
                    !$roleaction('Req Image') ||
                      data.req_Image_Done == 'Y' ||
                      data.graphicDsn_Qc_Status == 'Y' ||
                      check_enrich()
                  "
                  :value="e"
                  :id="e"
                  v-model="data.select_req_Image"
                />
                <label class="form-check-label" :for="e">
                  {{ `${e !== "OTHER" ? e : "อื่นๆ..."}` }}
                </label>
                <textarea
                  v-if="e == 'OTHER' && selectother_req_Image"
                  class="form-control"
                  type="text"
                  placeholder="อื่นๆ..."
                  v-model="data.other_req_Image"
                  maxlength="600"
                />
              </div>
            </div>

            <div class="col-12 mt-2">
              <div class="">
                <label>Remark1</label>
                <textarea
                  :disabled="
                    !$roleaction('Req Image') ||
                      data.graphicDsn_Qc_Status == 'Y' ||
                      check_enrich()
                  "
                  class="form-control "
                  v-model="data.remark1"
                ></textarea>
              </div>
            </div>
            <p
              class="font-0-7s px-3 pt-3"
              v-if="
                $serviceMain.split_name(
                  $store.getters['assigntasksActions/get_dataassign'].nameQCGd
                ) !== ''
              "
            >
              Update By :
              {{
                $serviceMain.split_name(
                  $store.getters["assigntasksActions/get_dataassign"].nameQCGd
                )
              }}
            </p>
            <div class="col-12 text-right mt-3">
              <button
                type="button"
                :disabled="
                  !$roleaction('Req Image') || data.graphicDsn_Qc_Status == 'Y'
                "
                class="btn bt-main px-4"
                @click="confirm_img()"
              >
                บันทึก
              </button>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "Preqc",
  data() {
    return {
      tabIndex: 0,
      data: {
        missing_Data_Done: "",
        req_Image_Done: "",
        qty_Corners: "",
        missing_Data: [],
        req_Image: [],
        select_missing_Data: [],
        other_missing_data: "",
        select_req_Image: [],
        other_req_Image: "",
        copyWr_Qc: "",
        graphicDsn_Qc: "",
        copyWr_Qc_Status: "",
        graphicDsn_Qc_Status: "",
        progress_Action: "",
        remark: "",
        cc_email: "",
        vendorPhone: "",
        vendorName: "",
        vendorEmail: "",
        vendorContact: "",
        sendToEmail: "",
        vendorWeb: "",
        remark1: "",
      },
      qty_Corners: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      missing_Data: [],
      req_Image: [],

      selectother_missing_data: false,

      selectother_req_Image: false,
    };
  },

  watch: {
    get_missing_Data: function() {
      this.getdata();
    },
    com_dataassign(v) {
      this.initdata_assign();
    },
    "data.select_req_Image": async function() {
      let type = typeof this.data.select_req_Image;
      //   this.data.graphicDsn_Qc = await this.$store.getters[
      //     "loginActions/GET_USER"
      //   ].id;
      if (type == "object") {
        let find = this.data.select_req_Image.findIndex((e) => e == "OTHER");
        if (find !== -1) {
          this.selectother_req_Image = true;
        } else {
          this.selectother_req_Image = false;
        }
      } else {
        this.selectother_req_Image = false;
      }
    },

    "data.select_missing_Data": async function() {
      let type = typeof this.data.select_missing_Data;

      //   this.data.copyWr_Qc = await this.$store.getters["loginActions/GET_USER"]
      //     .id;

      if (type == "object") {
        let find = this.data.select_missing_Data.findIndex((e) => e == "OTHER");

        if (find !== -1) {
          this.selectother_missing_data = true;
        } else {
          this.selectother_missing_data = false;
        }
      } else {
        this.selectother_missing_data = false;
      }
    },
    data: {
      async handler(val) {
        await this.$store.dispatch("assigntasksActions/set_preqc", val);
      },
      deep: true,
    },
  },
  computed: {
    get_missing_Data: function() {
      return this.$store.getters["assigntasksActions/get_missing_Data"];
    },
    com_dataassign: function() {
      return this.$store.getters["assigntasksActions/get_dataassign"];
    },
  },
  mounted() {
    this.getdata();
    this.initdata_assign();
  },
  methods: {
    check_enrich() {
      let data = this.$store.getters["enrichproductdetailActions/get_data"];

      if (data !== null) {
        const { enrich } = data;
        if (this.$serviceMain.chechnull(String(enrich).trim()) == "") {
          return false;
        } else {
          return true;
        }
      }
    },
    view(e) {
      let routeData = e;
      window.open(routeData, "_blank");
    },
    async confirm_missdata() {
      try {
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];

        if (
          this.data.missing_Data_Done == "N" &&
          this.$serviceMain.chechnull(data.missing_Data) == ""
        ) {
          throw "กรุณาเพิ่ม (ข้อมูลที่ขาด / ขอข้อมูลเพิ่ม) / Please add (missing information / request more information)";
        }

        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let getterdata = this.$store.getters[
            "enrichproductdetailActions/get_data"
          ];
          const { assign_Id, enrich } = getterdata;
          data.enrich = enrich;
          data.skCode =
            (await this.$serviceMain.chechnull(data.skCode)) == ""
              ? this.$route.params.skcode
              : data.skCode;
          data.assign_Id = await this.$serviceMain.chechnull(assign_Id);

          data.copyWr_Qc = await this.$store.getters["loginActions/GET_USER"]
            .id;

          data.copyWr_Qc_Status = "Y";
          if (this.data.missing_Data_Done == "Y") {
            data.missing_Data = [];
            data.contactVd_Cw_Status = "Y";
            data.push_data_already = "Y";
          } else {
            data.contactVd_Cw_Status = "N";
            data.push_data_already = "N";
          }

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser_preqc_data`,
            data,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.$emit("hide_and_get");
        }
      } catch (error) {
        console.log(error);
        alert(JSON.stringify(error));

        this.$emit("hide_and_get");
      }
    },
    async confirm_img() {
      try {
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];

        if (
          this.data.req_Image_Done == "N" &&
          this.$serviceMain.chechnull(data.req_Image) == ""
        ) {
          throw "กรุณาเพิ่ม ภาพที่ขอ / Please add the requested image";
        }

        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let getterdata = this.$store.getters[
            "enrichproductdetailActions/get_data"
          ];
          const { assign_Id, enrich } = getterdata;
          data.enrich = enrich;
          data.skCode =
            (await this.$serviceMain.chechnull(data.skCode)) == ""
              ? this.$route.params.skcode
              : data.skCode;
          data.assign_Id = await this.$serviceMain.chechnull(assign_Id);
          data.graphicDsn_Qc_Status = "Y";
          if (this.data.req_Image_Done == "Y") {
            data.req_Image = [];
            data.push_img_already = "Y";
            data.contactVd_Gd_Status = "Y";
          } else {
            data.push_img_already = "N";
            data.contactVd_Gd_Status = "N";
          }
          data.graphicDsn_Qc = await this.$store.getters[
            "loginActions/GET_USER"
          ].id;

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser_preqc_img`,
            data,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.$emit("hide_and_get");
        }
      } catch (error) {
        console.log(error);
        alert(JSON.stringify(error));
        this.$emit("hide_and_get");
      }
    },
    async getdata() {
      let missing_Data = await this.$store.getters[
        "assigntasksActions/get_missing_Data"
      ];

      let req_Image = await this.$store.getters[
        "assigntasksActions/get_req_Image"
      ];
      if (missing_Data !== null && req_Image !== null) {
        this.missing_Data = await this.value_array(missing_Data);
        this.req_Image = await this.value_array(req_Image);
      }
    },
    async value_array(data) {
      return new Promise(async (resolve, reject) => {
        var arr = [];
        for await (var [i, v] of data.entries()) {
          arr.push(v.detail);
        }
        resolve(arr);
      });
    },
    async initdata_assign() {
      let data = this.$store.getters["assigntasksActions/get_dataassign"];

      if (data !== null) {
        const {
          missing_Data,
          remark,
          remark1,
          req_Image,
          qty_Corners,
          graphicDsn_Qc,
          copyWr_Qc,
          copyWr_Qc_Status,
          graphicDsn_Qc_Status,
          missing_Data_Done,
          req_Image_Done,
          vendorPhone,
          vendorName,
          vendorEmail,
          vendorContact,
          progress_Action,
          sendToEmail,
          vendorWeb,
        } = data;
        this.data.progress_Action = this.$serviceMain.chechnull(
          progress_Action
        );
        this.data.qty_Corners = qty_Corners;
        this.data.remark = this.$serviceMain.chechnull(remark);
        this.data.remark1 = this.$serviceMain.chechnull(remark1);
        this.data.graphicDsn_Qc = this.$serviceMain.chechnull(graphicDsn_Qc);
        this.data.copyWr_Qc = this.$serviceMain.chechnull(copyWr_Qc);
        this.data.copyWr_Qc_Status =
          this.$serviceMain.chechnull(copyWr_Qc_Status) == ""
            ? "N"
            : copyWr_Qc_Status;
        this.data.graphicDsn_Qc_Status =
          this.$serviceMain.chechnull(graphicDsn_Qc_Status) == ""
            ? "N"
            : graphicDsn_Qc_Status;

        this.data.missing_Data_Done =
          this.$serviceMain.chechnull(missing_Data_Done) == ""
            ? "N"
            : missing_Data_Done;
        this.data.req_Image_Done =
          this.$serviceMain.chechnull(req_Image_Done) == ""
            ? "N"
            : req_Image_Done;
        this.data.vendorPhone = this.$serviceMain.chechnull(vendorPhone);
        this.data.vendorName = this.$serviceMain.chechnull(vendorName);
        this.data.vendorEmail = this.$serviceMain.chechnull(vendorEmail);
        this.data.vendorContact = this.$serviceMain.chechnull(vendorContact);
        this.data.sendToEmail = this.$serviceMain.chechnull(sendToEmail);
        this.data.vendorWeb = this.$serviceMain.chechnull(vendorWeb);
        //missing_Data
        this.data.select_missing_Data = String(missing_Data).split(",");
        let find_missing_Data = this.data.select_missing_Data.findIndex(
          (e) => e.split(":")[0] == "OTHER"
        );
        if (find_missing_Data !== -1) {
          this.data.other_missing_data = this.data.select_missing_Data[
            find_missing_Data
          ].split(":")[1];
          this.data.select_missing_Data[
            find_missing_Data
          ] = this.data.select_missing_Data[find_missing_Data].split(":")[0];
        }
        //_req_Imag

        this.data.select_req_Image = String(req_Image).split(",");
        let find_req_Imag = this.data.select_req_Image.findIndex(
          (e) => e.split(":")[0] == "OTHER"
        );
        if (find_req_Imag !== -1) {
          this.data.other_req_Image = this.data.select_req_Image[
            find_req_Imag
          ].split(":")[1];
          this.data.select_req_Image[
            find_req_Imag
          ] = this.data.select_req_Image[find_req_Imag].split(":")[0];
        }

        // ------ Alrt Vender Email

        if (this.data.vendorEmail == "") {
          this.$serviceMain.showWarningAlert1(
            this,
            `ไม่มีอีเมลล์สำหรับติดต่อผู้ขาย!/There is no email to contact the vender!`
          );
        }
      }
    },

    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
  },
};
</script>

<style scoped></style>
