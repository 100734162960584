<template>
  <b-modal id="ModalUrgentApproval" hide-footer no-close-on-backdrop hide-header hide-header-close size="lg">
    <div class="row m-0 p-0">
      <p class="font-600 font-1-2s">
        {{
          String(propsData)
            .trim()
            .toUpperCase() === "CW"
          ? "Urgent Approval Copy Writer"
          : String(propsData)
            .trim()
            .toUpperCase() === "GD"
            ? "Urgent Approval Graphic Design"
            : "No Department"
        }}
      </p>
      <!-- <div class="col-12">
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Remark</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="Remark"
            :maxlength="max"></textarea>
        </div>
        <p class="m-0 p-0 text-right">{{ `${Remark.length}/${max}` }}</p>
      </div> -->
      <div class="row w-100 col-12 mx-1 my-1" v-if="listR.length > 0">
        <h5>ข้อมูลที่ขาด / ขอข้อมูลเพิ่มเติม </h5>
        <div class="row col-12">
          <div class="col-6" v-for="(e, i) in listR" :key="i">
            <input class="form-check-input " type="checkbox" :id="i" :value="e.rejectName" v-model="selectedR" />
            <label class="form-check-label " :for="i">{{
              e.rejectName
            }}</label>
          </div>
        </div>
      </div>
      <div class="row w-100 pt-3 col-12 mx-1 my-1" v-if="listN.length > 0">
        <h5>ข้อมูลไม่สมบูรณ์</h5>
        <div class="row col-12">
          <div class="col-6" v-for="(e, i) in listN" :key="i">
            <input class="form-check-input " type="checkbox" :id="'N' + i" :value="e.rejectName" v-model="selectedN" />
            <label class="form-check-label " :for="'N' + i">{{
              e.rejectName
            }}</label>
          </div>
        </div>
      </div>
      <div class="row col-12 pt-3 mx-1 my-1" v-if="showRemark">
        <h5 class="mb-2">Remark </h5>
        <b-form-textarea id="textarea" v-model="remark" placeholder="Enter something..." rows="3"
          max-rows="6"></b-form-textarea>
        <p class="m-0 p-0 text-right">{{ `${remark.length}/${max}` }}</p>
      </div>
      <div class="col-12 col-lg-12 text-right  pt-4">
        <button type="button" class="btn bt-main mr-1" @click="save()">
          บันทึก
        </button>
        <button type="button" class="btn btn-danger ml-1" @click="hide()">
          ยกเลิก
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "ModalUrgentApproval",
  props: ["propsData"],
  data() {
    return {
      max: 200,
      listR: [],
      listN: [],
      selectedN: [],
      selectedR: [],
      remark: "",
      showRemark: false,
    };
  },
  computed: {},
  async mounted() {
    if (this.propsData === "CW" || this.propsData === "GD") {
      this.getRejectCheckBoxList()
    }
  },
  watch: {
    selectedR: function () {
      this.showRemark = this.selectedR.includes("อื่น ๆ")
      if (this.showRemark === false) {
        this.remark = ""
      }
    },
  },
  methods: {
    hide() {
      this.$bvModal.hide("ModalUrgentApproval");
    },
    // async validate() {
    //   return new Promise((resolve, reject) => {
    //     if (!!!this.Remark) {
    //       resolve("กรุณาระบุเหตุผล / Please enter remark");
    //     }
    //     resolve("OK");
    //   });
    // },
    validate() {
      const msgRemark = "กรุณากรอกเหตุผลในการปฏิเสธ"
      if (this.propsData === "CW" || this.propsData === "GD") {
        if (this.selectedR.length === 0 && this.selectedN.length === 0) {
          throw "กรุณาเลือกเหตุผล"
        }
        this.selectedR.forEach((item, index) => {
          if (item === "อื่น ๆ" && this.remark === "") {
            throw msgRemark
          }
        })
      }
    },
    async getRejectCheckBoxList() {

      let getAPI = await this.$serviceAPI.call_API(
        "get",
        `product/getrejectlistmenu`,
        {},
        1
      );
      this.listR = getAPI.data.filter(
        (x) => x.role === this.propsData && x.rejectType === "R"
      );
      // console.log(this.listR);
      this.listN = getAPI.data.filter(
        (x) => x.role === this.propsData && x.rejectType === "N"
      );
    },
    async save() {
      try {
        // let vali = await this.validate();
        // if (vali !== "OK") {
        //   throw vali;
        // }
        this.validate();

        let mss = `ยืนยันการอนุมติด่วน / Confirm urgent approval ?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          await this.$store.dispatch(
            "assigntasksActions/call_dataassign_again"
          );
          let data = await this.$store.getters[
            "assigntasksActions/get_dataassign"
          ];
          let get_data = await this.$store.getters[
            "enrichproductdetailActions/get_data"
          ];
          const { assign_Id, enrich } = get_data;
          const arrayRemarkReject = this.selectedR;
          // arrayRemarkReject.push(this.selectedN)
          arrayRemarkReject.forEach((element, index) => {
            if (element === "อื่น ๆ") {
              arrayRemarkReject[index] = element + " " + this.remark
            }
          });
          data.rejectType = String("UAP");
          if (this.propsData === "CW") {
            data.copyWr_Creator_Status = "Y";
            data.senior_copyWr_Staus = "Y";
            data.remarkRejectCW = String(arrayRemarkReject);

            data.role = String("CW");
            if (this.selectedN.length > 0) {
              // data.rejectType = String('N');
              data.Remark = String(this.selectedN);
            }
          } else {
            data.graphicDsn_Creator_Status = "Y";
            data.senior_graphicDsn_Status = "Y";
            data.remarkRejectGD = String(arrayRemarkReject);

            data.role = String("GD");
            if (this.selectedN.length > 0) {
              // data.rejectType = String('N');
              data.Remark = String(this.selectedN);
            }
          }
          data.senior_graphicDsn = await this.$store.getters[
            "loginActions/GET_USER"
          ].id;
          data.approved_Date = new Date();

          let val = {
            assign_Id: assign_Id,
            skCode: data.skCode,
            old_enrich: enrich,
            new_enrich:
              data.senior_graphicDsn_Status === "Y" &&
                data.senior_copyWr_Staus === "Y"
                ? "A"
                : enrich,
            update_By: this.$store.getters["loginActions/GET_USER"].id,
            role: data.role,
            rejectType: data.rejectType,
            Remark: data.Remark
          };
          // console.log(val);
          let _update_enrich = await this.$store.dispatch(
            "enrichproductdetailActions/update_enrich",
            val
          );
          //----- Update enrich -----
          if (_update_enrich !== "OK") {
            // console.log(_update_enrich);
            throw _update_enrich;
          }
          //   console.log(data);
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser`,
            data,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.$store.dispatch(
            "enrichproductdetailActions/getpimenrichproduct_data",
            this.$route.params.skcode
          );
          this.hide();
          // this.$emit("calldata");
        }
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>
<style></style>
