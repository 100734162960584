<template>
  <div class="row px-3 w-100">
    <div class="col-12">
      <h5>Contact vendor</h5>
    </div>
    <div class="col-6">
      <div class="form-group form-group-sm row">
        <label class="col-sm-4 col-form-label text-right"
          >Recrieve (Date)</label
        >
        <div class="col-sm-8">
          <input
            class="form-control form-control-sm"
            type="date"
            :disabled="
              (data.contactVd_Cw_Status == 'Y' &&
                data.contactVd_Gd_Status == 'Y') ||
                check_enrich()
            "
            v-model="data.recrieve_Date"
          />
        </div>
      </div>
    </div>
    <!-- ---------------------- -->
    <div class="col-6">
      <div class="form-group form-group-sm row">
        <label class="col-sm-4 col-form-label text-right"
          >จำนวนมุมภาพที่มี</label
        >
        <div class="col-sm-8 ">
          <select
            class="form-control form-control-sm"
            v-model="data.qty_Corners"
            disabled
          >
            <option value="">จำนวนมุมภาพที่มี...</option>
            <option v-for="(e, i) in qty_Corners" :key="i" :value="e">{{
              e
            }}</option>
          </select>
        </div>
      </div>
    </div>
    <!-- ---------------------- -->
    <div class="col-6">
      <div class="form-group form-group-sm row">
        <label class="col-sm-4 col-form-label text-right"
          >Progress / Action</label
        >
        <div class="col-sm-8">
          <select
            class="form-control form-control-sm "
            v-model="data.progress_Action"
            disabled
          >
            <option value="">Progress / Action...</option>
            <option v-for="(e, i) in progress_Action" :key="i" :value="e">{{
              e
            }}</option>
          </select>
        </div>
      </div>
    </div>
    <!-- ------------------- tab ----------------- -->
    <div class="col-12 m-0 p-0">
      <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
        <b-tab title="Copy Writer" :title-link-class="linkClass(0)" active>
          <div class="row" v-if="data.get_missing_Data.length > 0">
            <div class="col-12 ">
              <h5>
                ข้อมูลที่ขาด / ขอข้อมูลเพิ่ม
              </h5>
            </div>
            <div class="col-6" v-for="(e, i) in data.get_missing_Data" :key="i">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :disabled="
                    !$roleaction('Contac Vendor') ||
                      data.contactVd_Cw_Status == 'Y' ||
                      check_enrich()
                  "
                  :value="e"
                  :id="e"
                  v-model="data.select_missing_Data_Check"
                />
                <label class="form-check-label" :for="e">
                  {{ e }}
                </label>
              </div>
            </div>
            <div class="col-12">
              <p
                class="font-0-7s px-3 pt-3"
                v-if="
                  $serviceMain.split_name(
                    $store.getters['assigntasksActions/get_dataassign']
                      .nameContVdWr
                  ) !== ''
                "
              >
                Update By :
                {{
                  $serviceMain.split_name(
                    $store.getters["assigntasksActions/get_dataassign"]
                      .nameContVdWr
                  )
                }}
              </p>
            </div>

            <div class="col-12 text-right mt-3">
              <button
                type="button"
                :disabled="
                  !$roleaction('Req Missing Data') ||
                    data.contactVd_Cw_Status == 'Y' ||
                    data.push_data_already == 'Y' ||
                    check_enrich()
                "
                class="btn bt-bnb px-4 mx-1"
                @click="confirm_missdata_content()"
              >
                พร้อมเขียนคอนเทนต์
              </button>
              <button
                type="button"
                :disabled="
                  !$roleaction('Contac Vendor') ||
                    data.contactVd_Cw_Status == 'Y' ||
                    check_enrich()
                "
                class="btn bt-main px-4"
                @click="confirm_missdata()"
              >
                บันทึก
              </button>
            </div>
          </div>
          <div
            class="row justify-content-center"
            v-else-if="data.contactVd_Cw_Status == 'Y'"
          >
            <p class="text-center text-muted">
              *** ข้อมูลเสร็จสมบูรณ์/Data completed ***
            </p>
          </div>
          <div class="row justify-content-center" v-else>
            <p class="text-center text-muted">
              *** รอดำเนินการ Pre QC/Waiting Pre QC Procress ***
            </p>
          </div>
        </b-tab>
        <b-tab title="Graphic Design" :title-link-class="linkClass(1)">
          <div class="row" v-if="data.get_req_Image.length > 0">
            <!-- ---------------------------------- -->
            <div class="col-12 col-xl-6">
              <div class="form-group form-group-sm row">
                <label class="col-sm-4 col-form-label text-right"
                  >จำนวนมุมภาพที่ได้เพิ่ม</label
                >
                <div class="col-sm-8">
                  <select
                    class="form-control form-control-sm "
                    v-model="data.receive_more_image"
                    :disabled="
                      !$roleaction('Req Image') ||
                        data.contactVd_Gd_Status == 'Y' ||
                        check_enrich()
                    "
                  >
                    <option value="">จำนวนมุมภาพที่ได้เพิ่ม...</option>
                    <option v-for="(e, i) in qty_Corners" :key="i" :value="e">{{
                      e
                    }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12 ">
              <h5>
                ภาพที่ขอเพิ่ม
              </h5>
            </div>
            <div class="col-6" v-for="(e, i) in data.get_req_Image" :key="i">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :disabled="
                    !$roleaction('Contac Vendor') ||
                      data.contactVd_Gd_Status == 'Y' ||
                      check_enrich()
                  "
                  :value="e"
                  :id="e"
                  v-model="data.select_req_Image_Check"
                />
                <label class="form-check-label" :for="e">
                  {{ e }}
                </label>
              </div>
            </div>
            <div class="col-12">
              <p
                class="font-0-7s px-3 pt-3"
                v-if="
                  $serviceMain.split_name(
                    $store.getters['assigntasksActions/get_dataassign']
                      .nameContVdGd
                  ) !== ''
                "
              >
                Update By :
                {{
                  $serviceMain.split_name(
                    $store.getters["assigntasksActions/get_dataassign"]
                      .nameContVdGd
                  )
                }}
              </p>
            </div>

            <div class="col-12 text-right mt-3">
              <button
                type="button"
                :disabled="
                  !$roleaction('Req Image') ||
                    data.contactVd_Gd_Status == 'Y' ||
                    data.push_img_already == 'Y' ||
                    check_enrich()
                "
                class="btn bt-bnb px-4 mx-1"
                @click="confirm_img_content()"
              >
                พร้อมเขียนคอนเทนต์
              </button>
              <button
                type="button"
                :disabled="
                  !$roleaction('Contac Vendor') ||
                    data.contactVd_Gd_Status == 'Y' ||
                    check_enrich()
                "
                class="btn bt-main px-4"
                @click="confirm_img()"
              >
                บันทึก
              </button>
            </div>
          </div>
          <div
            class="row justify-content-center"
            v-else-if="data.contactVd_Gd_Status == 'Y'"
          >
            <p class="text-center text-muted">
              *** ข้อมูลเสร็จสมบูรณ์/Data completed ***
            </p>
          </div>
          <div class="row justify-content-center" v-else>
            <p class="text-center text-muted">
              *** รอดำเนินการ Pre QC/Waiting Pre QC Procress ***
            </p>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <!-- ---------------------- -->
    <!-- <p
      class="font-0-7s px-3 pt-3"
      v-if="
        $serviceMain.split_name(
          $store.getters['enrichproductdetailActions/get_data']
            .nameContactVendor
        ) !== ''
      "
    >
      Update By :
      {{
        $serviceMain.split_name(
          $store.getters["enrichproductdetailActions/get_data"]
            .nameContactVendor
        )
      }}
    </p> -->
    <!-- <div class="col-12 text-right my-3">
      <button
        type="button"
        :disabled="
          !$roleaction('Contac Vendor') || data.contact_vendor_Status == 'Y'
        "
        class="btn bt-main px-4"
        @click="confirm()"
      >
        บันทึก
      </button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Contactvendor",
  data() {
    return {
      tabIndex: 0,
      data: {
        get_missing_Data: [],
        get_req_Image: [],
        qty_Corners: "",
        progress_Action: "",
        contact_vendor_Status: "",
        recrieve_Date: null,
        receive_more_image: "",
        missing_Data_Check: "",
        req_Image_Check: "",
        select_missing_Data_Check: [],
        select_req_Image_Check: [],
        contactVd_Cw_Status: "",
        contactVd_Gd_Status: "",
        contactVd_Cw: "",
        contactVd_Gd: "",
        push_img_already: "",
        push_data_already: "",
      },
      qty_Corners: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      progress_Action: ["On Process", "Done"],
    };
  },
  watch: {
    com_dataassign(v) {
      this.initdata_assign();
    },
    data: {
      async handler(val) {
        await this.$store.dispatch("assigntasksActions/set_Contactvendor", val);
      },
      deep: true,
    },
  },
  computed: {
    com_dataassign: function() {
      return this.$store.getters["assigntasksActions/get_dataassign"];
    },
  },
  mounted() {
    this.initdata_assign();
  },
  methods: {
    async confirm_img_content() {
      try {
        // console.log(this.data.push_img_already);
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];

        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let getterdata = await this.$store.getters[
            "enrichproductdetailActions/get_data"
          ];
          const { assign_Id, enrich } = getterdata;
          data.enrich = enrich;
          data.skCode =
            (await this.$serviceMain.chechnull(data.skCode)) == ""
              ? this.$route.params.skcode
              : data.skCode;
          data.assign_Id = await this.$serviceMain.chechnull(assign_Id);

          data.push_img_already_user = await this.$store.getters[
            "loginActions/GET_USER"
          ].id;

          data.push_img_already = "Y";
          if (data.push_img_already == "Y" && data.push_data_already == "Y") {
            //----- Update enrich -----
            if (enrich == "I") {
              let val = {
                assign_Id: assign_Id,
                skCode: data.skCode,
                old_enrich: enrich,
                new_enrich: "C",
                update_By: this.$store.getters["loginActions/GET_USER"].id,
              };
              let _update_enrich = await this.$store.dispatch(
                "enrichproductdetailActions/update_enrich",
                val
              );
              if (_update_enrich !== "OK") {
                console.log(_update_enrich);
                throw _update_enrich;
              }
            }

            //----- Update enrich -----
          }

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser`,
            data,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.$emit("hide_and_get");
        }
      } catch (error) {
        console.log(error);
        alert(JSON.stringify(error));
      }
    },
    async confirm_missdata_content() {
      try {
        // console.log(this.data.push_data_already);
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];

        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let getterdata = await this.$store.getters[
            "enrichproductdetailActions/get_data"
          ];
          const { assign_Id, enrich } = getterdata;
          data.enrich = enrich;
          data.skCode =
            (await this.$serviceMain.chechnull(data.skCode)) == ""
              ? this.$route.params.skcode
              : data.skCode;
          data.assign_Id = await this.$serviceMain.chechnull(assign_Id);

          data.push_data_already__user = await this.$store.getters[
            "loginActions/GET_USER"
          ].id;

          data.push_data_already = "Y";
          if (data.push_img_already == "Y" && data.push_data_already == "Y") {
            //----- Update enrich -----
            if (enrich == "I") {
              let val = {
                assign_Id: assign_Id,
                skCode: data.skCode,
                old_enrich: enrich,
                new_enrich: "C",
                update_By: this.$store.getters["loginActions/GET_USER"].id,
              };
              let _update_enrich = await this.$store.dispatch(
                "enrichproductdetailActions/update_enrich",
                val
              );
              if (_update_enrich !== "OK") {
                console.log(_update_enrich);
                throw _update_enrich;
              }
            }

            //----- Update enrich -----
          }

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser`,
            data,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.$emit("hide_and_get");
        }
      } catch (error) {
        console.log(error);
        alert(JSON.stringify(error));
      }
    },
    check_enrich() {
      let data = this.$store.getters["enrichproductdetailActions/get_data"];

      if (data !== null) {
        const { enrich } = data;
        if (this.$serviceMain.chechnull(String(enrich).trim()) !== "") {
          return false;
        } else {
          return true;
        }
      }
    },
    async initdata_assign() {
      let data = this.$store.getters["assigntasksActions/get_dataassign"];

      if (data !== null) {
        const {
          qty_Corners,
          progress_Action,
          contact_vendor_Status,
          recrieve_Date,
          recrieve_Date_convert,
          receive_more_image,
          missing_Data,
          req_Image,
          missing_Data_Check,
          req_Image_Check,
          contactVd_Cw_Status,
          contactVd_Gd_Status,
          contactVd_Cw,
          contactVd_Gd,
          push_img_already,
          push_data_already,
        } = data;

        this.data.push_img_already = this.$serviceMain.chechnull(
          push_img_already
        );
        this.data.push_data_already = this.$serviceMain.chechnull(
          push_data_already
        );

        this.data.contactVd_Gd = this.$serviceMain.chechnull(contactVd_Gd);
        this.data.contactVd_Cw = this.$serviceMain.chechnull(contactVd_Cw);
        this.data.contactVd_Gd_Status = this.$serviceMain.chechnull(
          contactVd_Gd_Status
        );
        this.data.contactVd_Cw_Status = this.$serviceMain.chechnull(
          contactVd_Cw_Status
        );
        this.data.req_Image_Check = this.$serviceMain.chechnull(
          req_Image_Check
        );
        this.data.missing_Data_Check = this.$serviceMain.chechnull(
          missing_Data_Check
        );

        this.data.receive_more_image = this.$serviceMain.chechnull(
          receive_more_image
        );
        this.data.qty_Corners = this.$serviceMain.chechnull(qty_Corners);
        this.data.progress_Action = this.$serviceMain.chechnull(
          progress_Action
        );

        this.data.contact_vendor_Status = this.$serviceMain.chechnull(
          contact_vendor_Status
        );

        this.data.recrieve_Date =
          this.$serviceMain.chechnull(recrieve_Date) !== ""
            ? this.$dateFormat(
                new Date(recrieve_Date).toISOString(),
                "yyyy-mm-dd"
              )
            : null;

        //missing_Data
        this.data.get_missing_Data = String(missing_Data).split(",");
        let find_missing_Data_blank = this.data.get_missing_Data.findIndex(
          (e) => e == ""
        );

        if (find_missing_Data_blank !== -1) {
          this.data.get_missing_Data.splice(find_missing_Data_blank, 1);
        }

        //  select
        this.data.select_missing_Data_Check = String(missing_Data_Check).split(
          ","
        );
        let missing_Data_Check_blank = this.data.select_missing_Data_Check.findIndex(
          (e) => e == ""
        );

        if (missing_Data_Check_blank !== -1) {
          this.data.select_missing_Data_Check.splice(
            missing_Data_Check_blank,
            1
          );
        }

        //req_Image

        this.data.get_req_Image = String(req_Image).split(",");
        let find_req_Imag_blank = this.data.get_req_Image.findIndex(
          (e) => e == ""
        );

        if (find_req_Imag_blank !== -1) {
          this.data.get_req_Image.splice(find_req_Imag_blank, 1);
        }

        //  select

        this.data.select_req_Image_Check = String(req_Image_Check).split(",");
        let req_Image_Check_blank = this.data.select_req_Image_Check.findIndex(
          (e) => e == ""
        );

        if (req_Image_Check_blank !== -1) {
          this.data.select_req_Image_Check.splice(req_Image_Check_blank, 1);
        }
      }
    },
    async confirm_missdata() {
      try {
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];

        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let getterdata = await this.$store.getters[
            "enrichproductdetailActions/get_data"
          ];
          const { assign_Id, enrich } = getterdata;
          let user = await this.$store.getters["loginActions/GET_USER"];
          let { id } = user;
          data.enrich = enrich;
          data.skCode =
            (await this.$serviceMain.chechnull(data.skCode)) == ""
              ? this.$route.params.skcode
              : data.skCode;
          data.assign_Id = await this.$serviceMain.chechnull(assign_Id);

          data.contactVd_Cw = id;

          if (
            data.contactVd_Cw_Status == "Y" &&
            data.contactVd_Gd_Status == "Y"
          ) {
            data.progress_Action = "Done";
            data.contact_vendor_Status = "Y";

            //----- Update enrich -----
            if (enrich == "I") {
              let val = {
                assign_Id: assign_Id,
                skCode: data.skCode,
                old_enrich: enrich,
                new_enrich: "C",
                update_By: this.$store.getters["loginActions/GET_USER"].id,
              };
              let _update_enrich = await this.$store.dispatch(
                "enrichproductdetailActions/update_enrich",
                val
              );
              if (_update_enrich !== "OK") {
                console.log(_update_enrich);
                throw _update_enrich;
              }
            }

            //----- Update enrich -----
          }

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser`,
            data,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.$emit("hide_and_get");
        }
      } catch (error) {
        console.log(error);
        alert(JSON.stringify(error));
      }
    },
    async confirm_img() {
      try {
        // --------------- get all status fof pre qc again before save for update last status -------
        await this.$store.dispatch("assigntasksActions/call_dataassign_again");
        // --------------- end get all status fof pre qc again before save for update last status -------
        let data = await this.$store.getters[
          "assigntasksActions/get_dataassign"
        ];

        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let getterdata = await this.$store.getters[
            "enrichproductdetailActions/get_data"
          ];
          const { assign_Id, enrich } = getterdata;

          let user = await this.$store.getters["loginActions/GET_USER"];
          let { id } = user;
          data.enrich = enrich;
          data.skCode =
            (await this.$serviceMain.chechnull(data.skCode)) == ""
              ? this.$route.params.skcode
              : data.skCode;
          data.assign_Id = await this.$serviceMain.chechnull(assign_Id);

          data.contactVd_Gd = id;
          // data.graphicDsn_Qc = await this.$store.getters[
          //   "loginActions/GET_USER"
          // ].id;

          if (
            data.contactVd_Cw_Status == "Y" &&
            data.contactVd_Gd_Status == "Y"
          ) {
            data.progress_Action = "Done";
            data.contact_vendor_Status = "Y";

            //----- Update enrich -----
            if (enrich == "I") {
              let val = {
                assign_Id: assign_Id,
                skCode: data.skCode,
                old_enrich: enrich,
                new_enrich: "C",
                update_By: this.$store.getters["loginActions/GET_USER"].id,
              };
              let _update_enrich = await this.$store.dispatch(
                "enrichproductdetailActions/update_enrich",
                val
              );
              if (_update_enrich !== "OK") {
                console.log(_update_enrich);
                throw _update_enrich;
              }
            }

            //----- Update enrich -----
          }

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser`,
            data,
            1
          );

          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.$emit("hide_and_get");
        }
      } catch (error) {
        console.log(error);
        alert(JSON.stringify(error));
      }
    },
    async confirm() {
      try {
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let getterdata = this.$store.getters[
            "enrichproductdetailActions/get_data"
          ];
          const { assign_Id } = getterdata;

          let data = this.$store.getters["assigntasksActions/get_dataassign"];
          data.skCode =
            (await this.$serviceMain.chechnull(data.skCode)) == ""
              ? this.$route.params.skcode
              : data.skCode;
          data.assign_Id = await this.$serviceMain.chechnull(assign_Id);

          if (
            String(data.progress_Action)
              .trim()
              .toUpperCase() == "DONE"
          ) {
            data.contact_vendor_Status = "Y";
          }

          data.contact_vendor = await this.$store.getters[
            "loginActions/GET_USER"
          ].id;
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.$emit("hide_and_get");
        }
      } catch (error) {
        console.log(error);
        alert(JSON.stringify(error));
      }
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
  },
};
</script>

<style></style>
