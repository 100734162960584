<template>
  <div id="Generalinfomation" class="row">
    <div class="col-12 p-5 shadow p-3 mb-4 bg-white rounded">
      <p class="font-1-2s font-600">ข้อมูลทั่วไป</p>
      <!-- ------------------------ -->
      <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">ชื่อสินค้า</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">TH</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ชื่อสินค้า TH"
                  maxlength="100"
                  v-model="data.prNameTH"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">EN</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="ชื่อสินค้า EN"
                  maxlength="100"
                  v-model="data.prNameEN"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------- -->
      <!-- <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">promotext</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">TH</span>
                </div>
                <textarea
                  class="form-control"
                  rows="3"
                  v-model="data.promotextTH"
                ></textarea>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">EN</span>
                </div>
                <textarea
                  class="form-control"
                  rows="3"
                  v-model="data.promotextEN"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- ------------------------------- -->
      <!-- <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">metaTitle</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">TH</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="metaTitle TH"
                  v-model="data.metaTitleTH"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">EN</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="metaTitle EN"
                  v-model="data.metaTitleEN"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- ------------------------------- -->
      <!-- <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">metaKeywords</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">TH</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="metaKeywords TH"
                  v-model="data.metaKeywordsTH"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">EN</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="metaKeywords EN"
                  v-model="data.metaKeywordsEN"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- ------------------------------- -->
      <!-- <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">metaDescription</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">TH</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="metaDescription TH"
                  v-model="data.metaDescriptionTH"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">EN</span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="metaDescription EN"
                  v-model="data.metaDescriptionEN"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- ------------------------------- -->
      <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">เเสดงบนเว็บไซต์</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row px-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                disabled="disabled"
                type="checkbox"
                v-model="showonweball"
                @change="selectall($event)"
              />
              <label class="form-check-label">ALL</label>
            </div>
            <div
              class="form-check form-check-inline"
              v-for="(e, i) in showonweb"
              :key="i"
            >
              <input
                class="form-check-input"
                disabled="disabled"
                type="checkbox"
                :value="e"
                v-model="data.showonweb"
              />
              <label class="form-check-label">{{ e }}</label>
            </div>
          </div>
        </div>
      </div>
      <!-- ------------------------------- -->
      <!-- <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">สถานะ</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row px-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                disabled="disabled"
                type="checkbox"
                v-model="statusall"
                @change="selectstatusall($event)"
              />
              <label class="form-check-label">ALL</label>
            </div>
            <div
              class="form-check form-check-inline"
              v-for="(e, i) in status"
              :key="i"
            >
              <input
                class="form-check-input"
                disabled="disabled"
                type="checkbox"
                :value="e"
                v-model="data.status"
              />
              <label class="form-check-label">{{ e }}</label>
            </div>
          </div>
        </div>
      </div> -->
      <!-- ------------------------------- -->
      <div class="row form-inline">
        <div class="col-12 col-lg-2 mb-4 text-right">MMS Category</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    >{{ String(data.CATMMS).split(":")[0] }}
                  </span>
                </div>
                <input
                  readonly="readonly"
                  type="text"
                  class="form-control"
                  placeholder="MMS Category Name"
                  v-model="String(data.CATMMS).split(':')[1]"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2 mb-4 text-right">PWB Category</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{
                    String(data.CATTWD).split(":")[0]
                  }}</span>
                </div>
                <input
                  readonly="readonly"
                  type="text"
                  class="form-control"
                  placeholder="PWB Category Name"
                  v-model="String(data.CATTWD).split(':')[1]"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- 
        <div class="col-12 col-lg-2 mb-4 text-right">BNB Category</div>
        <div class="col-12 col-lg-10 mb-4">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text "
                    >{{ String(data.CATBNB).split(":")[0] }}
                  </span>
                </div>
                <input
                  readonly="readonly"
                  type="text"
                  class="form-control"
                  placeholder="BNB Category Name"
                  v-model="String(data.CATBNB).split(':')[1]"
                />
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- ------------------------------- -->
      <div class="row">
        <div class="col-12 col-lg-2  mb-4  text-right">Slug</div>
        <div class="col-12 col-lg-10 mb-4">
          {{ data.SlugName }}
          <span
            @click="gotoSlug"
            type="button"
            class="link-PWB mx-3"
            v-if="data.SlugName !== ''"
            v-html="$svg_icon.Box_arrow_in_up_right"
          ></span>
        </div>
      </div>
      <!-- ------------------------ -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showonweball: false,
      statusall: false,
      data: {
        prNameTH: "",
        prNameEN: "",

        // metaTitleTH: "",
        // metaTitleEN: "",
        // metaKeywordsTH: "",
        // metaKeywordsEN: "",
        // metaDescriptionTH: "",
        // metaDescriptionEN: "",
        showOnWeb: "",
        showonweb: [],
        status: [],
        visible: "",
        installationService: "",
        installmentPayment: "",
        CATMMS: "",
        CATTWD: "",
        CATBNB: "",
        SlugName: "",
      },
      showonweb: ["PWB"],
      status: ["Visible", "InstallationService", "InstallmentPayment"],
    };
  },
  watch: {
    "data.showonweb": function() {
      this.showonweball =
        this.showonweb.length == this.data.showonweb.length ? true : false;
      this.setshowonwebconvert();
    },
    "data.status": function() {
      this.statusall =
        this.status.length == this.data.status.length ? true : false;

      this.data.visible =
        this.data.status.find((e) => e == "Visible") == undefined ? "N" : "Y";
      this.data.installationService =
        this.data.status.find((e) => e == "InstallationService") == undefined
          ? "N"
          : "Y";
      this.data.installmentPayment =
        this.data.status.find((e) => e == "InstallmentPayment") == undefined
          ? "N"
          : "Y";
    },
    com_get_data: function(v) {
      this.get_data();
    },
    data: {
      handler(val) {
        this.$store.dispatch("enrichproductdetailActions/setgeneral", val);
      },
      deep: true,
    },
  },

  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    gotoSlug() {
      let link = this.data.SlugName;
      window.open(link, "_blank", "noreferrer");
    },
    async get_data() {
      let data = this.$store.getters["enrichproductdetailActions/get_data"];

      if (data !== null) {
        const {
          prNameTH,
          prNameEN,

          // metaTitleTH,
          // metaTitleEN,
          // metaKeywordsTH,
          // metaKeywordsEN,
          // metaDescriptionTH,
          // metaDescriptionEN,
          showOnWeb,
          visible,
          installationService,
          installmentPayment,
          CATMMS,
          CATTWD,
          CATBNB,
          SlugName,
        } = data;
        this.data = {
          prNameTH: prNameTH,
          prNameEN: prNameEN,

          // metaTitleTH: metaTitleTH,
          // metaTitleEN: metaTitleEN,
          // metaKeywordsTH: metaKeywordsTH,
          // metaKeywordsEN: metaKeywordsEN,
          // metaDescriptionTH: metaDescriptionTH,
          // metaDescriptionEN: metaDescriptionEN,
          showOnWeb: showOnWeb,
          showonweb: await this.onweb(showOnWeb),
          status: [],
          visible: visible,
          installationService: installationService,
          installmentPayment: installmentPayment,
          CATMMS,
          CATTWD,
          CATBNB,
          SlugName,
        };

        this.showonweball =
          this.showonweb.length == this.data.showonweb.length ? true : false;
        this.setstatus();
      }
    },
    async onweb(data) {
      let datatrim = data.split("|");
      let _trim = [];
      for await (var [i, v] of datatrim.entries()) {
        _trim.push(String(v).trim());
      }

      return _trim;
    },
    async setstatus() {
      if (String(this.data.visible).trim() == "Y") {
        this.data.status.push("Visible");
      }
      if (String(this.data.installationService).trim() == "Y") {
        this.data.status.push("InstallationService");
      }
      if (String(this.data.installmentPayment).trim() == "Y") {
        this.data.status.push("InstallmentPayment");
      }
    },
    setshowonwebconvert() {
      // this.data.showOnWeb =
      //   this.showonweball == true ? "BNB|TWD" : this.data.showonweb.join("");
    },
    selectall(e) {
      e.target.checked
        ? (this.data.showonweb = this.showonweb)
        : (this.data.showonweb = []);
    },
    selectstatusall(e) {
      e.target.checked
        ? (this.data.status = this.status)
        : (this.data.status = []);
    },
  },
};
</script>

<style></style>
