import { render, staticRenderFns } from "./ModalUrgentApproval.vue?vue&type=template&id=794a1569&"
import script from "./ModalUrgentApproval.vue?vue&type=script&lang=js&"
export * from "./ModalUrgentApproval.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports