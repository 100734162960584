<template>
  <b-modal
    id="Modalremarks"
    hide-footer
    no-close-on-backdrop
    hide-header-close
    scrollable
    size="xl"
    title="Add Remarks"
  >
    <template #modal-header="">
      <div class="row w-100">
        <div class="col-10">
          <h5>เพิ่ม/เปลี่ยน Remark</h5>
        </div>
        <div class="col-2 mb-4 text-right">
          <span type="button" @click="close()">
            x
          </span>
        </div>

        <div class="col-12 col-xl-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>

            <input
              type="search"
              class="form-control"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn bt-main" @click="Search()">ค้นหา</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6">
          <div class="row  ">
            <div class="col-12 col-lg-3 text-right mb-3">
              หมวดหมู่ <span class="text-danger"></span>
            </div>
            <div class="col-12 col-lg-9 mb-3">
              <div class="input-group input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="selectnow"
                  @click="CallModalfindbycatcode()"
                />
                <div class="input-group-append">
                  <button class="input-group-text" @click="Clearcatcode()">
                    x
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12"
          v-if="
            data.remarkId !== '' &&
              data.remarkId !== null &&
              data.remarkId !== 'null'
          "
        >
          <div class="row">
            <div class="col-10"><p v-html="data.remarkHtmlTH"></p></div>
            <div class="col-2 text-right">
              <button
                type="button"
                class="btn btn-sm btn-danger "
                @click="removeremark()"
              >
                REMOVE
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-12  ">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(hide)="row">
            <b-icon
              type="button"
              :icon="row.detailsShowing ? 'chevron-up' : 'chevron-down'"
              aria-hidden="true"
              @click="row.toggleDetails"
            >
            </b-icon>
          </template>
          <template #cell(showAll)="row">
            <span class="badge badge-main" v-if="row.item.showAll == 1">
              YES</span
            >
            <span class="badge badge-info-o" v-else> NO</span>
          </template>
          <template #cell(active)="row">
            <span class="badge badge-main" v-if="row.item.active == 1">
              YES</span
            >
            <span class="badge badge-info-o" v-else> NO</span>
          </template>
          <template #cell(action)="row">
            <button
              type="button"
              class="btn btn-sm bt-main p-1"
              @click="select(row.item)"
            >
              SELECT
            </button>
          </template>
          <template #row-details="row">
            <b-card >
              <b-row class="m-1"> TH
                <p v-html="row.item.remarkHtmlTH"></p>
              </b-row>
              <b-row class="m-1"> EN
                <p v-html="row.item.remarkHtmlEN"></p>
              </b-row>
            </b-card>
          </template>
        </b-table>
      </div>
      <Modalfindbycatcode :key="reModalfindbycatcode" />
    </div>
  </b-modal>
</template>

<script>
import Modalfindbycatcode from "../../../components/Manage/Remarks/Modalfindbycatcode";
export default {
  name: "Modalremarks",
  components: { Modalfindbycatcode },
  data() {
    return {
      selectnow: this.$store.getters["categoryActions/get_category_remark"]
        .selectnow,
      catcode: this.$store.getters["categoryActions/get_category_remark"]
        .catCode,
      reModalfindbycatcode: Date.now() + 150,
      mode: "explain",
      find: "",
      select_mode: [
        { name: "ค้นหาด้วยคำอธิบาย", key: "explain" },
        { name: "ค้นหาโดยผู้อัพเดท", key: "updateByname" },
        { name: "ค้นหา remarkHtmlTH", key: "remarkHtmlTH" },
      ],
      fields: [
        { key: "hide", label: "" },
        { key: "explain", label: "คำอธิบาย" },
        { key: "subDept", label: "subDept" },
        // { key: "remarkHtmlTH", label: "remarkHtmlTH" },
        // { key: "remarkHtmlEN", label: "RemarkHtmlEN" },
        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "20%" },
        },
      ],
      itemlist: [],
      items: [],
      data: {
        remarkId: "",
        remarkAll: "",
        remarkHtmlTH: "",
      },
    };
  },
  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
    getcategory: function() {
      return this.$store.getters["categoryActions/get_category_remark"];
    },
  },
  watch: {
    getcategory: function(v) {
      this.selectnow = v.selectnow;
      this.catcode = v.catcode;
      this.getremarkdetail();
    },
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
    com_get_data: function(v) {
      this.get_data();
    },
  },
  mounted() {
    this.getremarkdetail();
    this.get_data();
    this.Clearcatcode();
  },
  methods: {
    async removeremark() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการลบ Remark หรือไม่? / Do you want to delete the Remark?"
      );
      if (confirm) {
        let data = {
          remarkId: "",
          remarkHtmlTH: "",
          remarkAll: this.data.remarkAll,
        };
        await this.$store.dispatch(
          "enrichproductdetailActions/setremark",
          data
        );
        this.$emit("get_data");
        this.hide();
      }
    },
    async get_data() {
      // this.redata();
      let data = this.$store.getters["enrichproductdetailActions/get_data"];

      if (data !== null) {
        const { remarkId, remarkAll, remarkHtmlTH } = data;

        this.data = {
          remarkId: remarkId,
          remarkAll: remarkAll,
          remarkHtmlTH: remarkHtmlTH,
        };
      }
    },
    CallModalfindbycatcode() {
      this.reModalfindbycatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalfindbycatcoderemark");
      }, 100);
    },
    Clearcatcode() {
      this.selectnow = "";
      this.catcode = "";
      this.$store.dispatch("categoryActions/resetcategoryremark");
    },
    async select(e) {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการเลือก Remark นี้หรือไม่? / Would you like to choose this remark?"
      );
      if (confirm) {
        await this.$store.dispatch("enrichproductdetailActions/setremark", e);
        this.$emit("get_data");
        this.hide();
      }
    },
    unSearch() {
      this.items = this.itemlist;
    },
    Search() {
      // console.log(data);
      var results = [];
      if (this.find == "") {
        this.getremarkdetail();
      } else {
        let find = this.find;
        if (this.mode !== "showAll" && this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          // for (var key in objects[i]) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
          // }
        }
        this.items = results;
      }
    },
    async getremarkdetail() {
      try {
        let data = {
          remarkId: "",
          catCode: this.catcode == undefined ? "" : this.catcode,
          catProduct:
            this.$store.getters["enrichproductdetailActions/get_data"] == null
              ? ""
              : this.$store.getters["enrichproductdetailActions/get_data"]
                  .catCode,
        };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `remark/getpimremarkactive`,
          data,
          1
        );
        this.items = getAPI.data;
        this.itemlist = getAPI.data;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async close() {
      this.hide();
    },
    redata() {
      this.data = { explain: "", remarkHtmlTH: "", showAll: 0, active: 1 };
    },
    hide() {
      this.$bvModal.hide("Modalremarks");
    },
  },
};
</script>

<style></style>
