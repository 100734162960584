<template>
  <div class="container-fluid m-0 p-0">
    <div class="row w-100 mx-0 mt-3 pb-3 bg-access" :key="re">
      <!-- <div class="col-12 ">
        <p style="display: flex">
          <span
            v-html="$svg_icon.edit"
            type="button"
            class=" text-black-50"
            style="flex: 1; text-align: right; white-space: nowrap;"
            @click="callModalaccess('e')"
          ></span>
        </p>
      </div> -->

      <div class="col-12" v-for="(e, i) in useraccess" :key="i">
        <!-- <p
          class="m-0 p-0 font-0-8s"
          :class="enrich == 'A' && i == 0 ? 'mt-3' : ''"
          style="display: flex"
        >
          <span
            v-if="enrich !== 'A' && i == 0 && $roleaction('Can Assign Tasks')"
            type="  "
            class=" btn btn-sm btn-info"
            style="flex: 1; text-align: right; white-space: nowrap;"
            @click="callModalaccess('e')"
          >
            <span>Overall Process</span> <span v-html="$svg_icon.Magic"></span
          ></span>
        </p> -->
        <div
          class="row justify-content-end p-1"
          :class="enrich == 'A' && i == 0 ? 'mt-3' : ''"
        >
          <button
            @click="callModalaccess('e')"
            v-if="enrich !== 'A' && i == 0 && $roleaction('Can Assign Tasks')"
            class=" btn btn-sm bt-bnb m-0 p-1"
          >
            Overall Process <span v-html="$svg_icon.Magic"></span>
          </button>
        </div>
        <p class="  m-0 p-0 font-500 font-0-8s">
          {{ e.name }}
        </p>
        <div class="m-0 p-0" v-if="e.user.length !== 0">
          <p
            class="  m-0 p-0 font-0-8s text-main"
            v-for="(ee, ii) in e.user"
            :key="ii + re"
          >
            <span
              v-html="$svg_icon.user"
              type="button"
              class="mx-1 text-main"
            ></span>
            {{ ee }}
          </p>
        </div>

        <p v-else class="  m-0 p-0 font-0-8s text-danger">
          *** ยังไม่มีผู้รับผิดชอบ ***
        </p>

        <hr class=" " />
      </div>
      <div class="col-12">
        <p class="  m-0 p-0 font-500 font-0-8s">
          User Approval Latest
        </p>
        <p
          v-if="data_log_enrich.length !== 0"
          class="  m-0 p-0 font-0-8s text-main"
        >
          {{ data_log_enrich.userName }}
          <span>{{ data_log_enrich.C_latestUpdate }}</span>
        </p>
        <p v-else class="  m-0 p-0 font-0-8s text-danger">
          *** ยังไม่มีผู้รับผิดชอบ ***
        </p>
        <hr class=" " />
      </div>
    </div>
    <Modalaccess
      :key="reModalaccess"
      :dataaccess="dataaccess"
      @get_data="get_data"
    />
    <Modalaccessreq :key="reModalaccessreq" :dataaccess="dataaccessreq" />
  </div>
</template>

<script>
import Modalaccess from "@/components/Products/EnrichProductDetail/Modalaccess.vue";
import Modalaccessreq from "@/components/Products/EnrichProductDetail/Modalaccessreq.vue";
export default {
  name: "Access",
  components: { Modalaccess, Modalaccessreq },
  data() {
    return {
      enrich: "",
      re: Date.now() + 50,
      reModalaccess: Date.now(),
      reModalaccessreq: Date.now() + 20,
      dataaccessreq: { name: "", id: "", user: "" },
      dataaccess: {
        name: "",
        permission_id: "",
        user: "",
      },
      data_log_enrich: [],
      useraccess: [
        {
          name: "Pre QC",
          user: [],
          id: "",
          mode: "data",
          email: "",
        },
        {
          name: "Contact vendor",
          user: [],
          id: "",
          mode: "",
          email: "",
        },
        {
          name: "Create New Product",
          user: [],
          id: "",
          mode: "img",
          email: "",
        },
        {
          name: "Approval",
          user: [],
          id: "",
          mode: "img",
          email: "",
        },
      ],
    };
  },
  watch: {
    com_get_data: function(v) {
      this.get_data_assing();
    },
    com_get_dataassign: function(v) {
      this.get_data_assing();
    },
  },
  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
    com_get_dataassign: function() {
      return this.$store.getters["assigntasksActions/get_dataassign"];
    },
  },
  async mounted() {
    this.get_data_assing();
  },
  methods: {
    checkpermissreq(id) {
      let user = this.$store.getters["loginActions/GET_USER"];

      const { id: userid, role } = user;
      let find_role_it = role.findIndex((e) => e.role_name == "IT");

      if (id == userid) {
        return true;
      } else if (find_role_it !== -1) {
        return true;
      } else {
        return false;
      }
    },
    reuser() {
      this.useraccess = [
        {
          name: "Pre QC",
          user: [],
          id: "",
          mode: "data",
          email: "",
        },
        {
          name: "Contact vendor",
          user: [],
          id: "",
          mode: "",
          email: "",
        },
        {
          name: "Create New Product",
          user: [],
          id: "",
          mode: "img",
          email: "",
        },
        {
          name: "Approval",
          user: [],
          id: "",
          mode: "img",
          email: "",
        },
      ];
    },
    async get_data_assing() {
      this.reuser();
      let data = await this.$store.getters[
        "enrichproductdetailActions/get_data"
      ];
      let data1 = await this.$store.getters[
        "assigntasksActions/get_dataassign"
      ];

      let data_log_enrich = await this.$store.getters[
        "assigntasksActions/get_data_log_enrich"
      ];
      if (data_log_enrich !== null) {
        this.data_log_enrich = data_log_enrich;
      }
      if (data !== null && data1 !== null) {
        const {
          nameCreator,
          nameContactVendor,
          idQc,
          idCt,
          idDsn,
          emailQc,
          emailCreator,
          emailGraphicDsn,
          enrich,
        } = data;

        const {
          nameContVdGd,
          nameContVdWr,
          nameCreatorWr,
          nameCreatorGd,
          nameQCGd,
          nameQcWr,
          nameSeniorCopyWr,
          nameSeniorGraphicDsn,
        } = data1;
        // PRE QC

        if (this.$serviceMain.chechnull(nameQcWr) !== "") {
          this.useraccess[0].user[0] = `CW : ${this.$serviceMain.split_name(
            nameQcWr
          )}`;
        }
        if (this.$serviceMain.chechnull(nameQCGd) !== "") {
          this.useraccess[0].user[1] = `GD : ${this.$serviceMain.split_name(
            nameQCGd
          )}`;
        }
        // ContactVendor
        if (this.$serviceMain.chechnull(nameContVdWr) !== "") {
          this.useraccess[1].user[0] = `CW : ${this.$serviceMain.split_name(
            nameContVdWr
          )}`;
        }
        if (this.$serviceMain.chechnull(nameContVdGd) !== "") {
          this.useraccess[1].user[1] = `GD : ${this.$serviceMain.split_name(
            nameContVdGd
          )}`;
        }

        // Creator
        if (this.$serviceMain.chechnull(nameCreatorWr) !== "") {
          this.useraccess[2].user[0] = `CW : ${this.$serviceMain.split_name(
            nameCreatorWr
          )}`;
        }
        if (this.$serviceMain.chechnull(nameCreatorGd) !== "") {
          this.useraccess[2].user[1] = `GD : ${this.$serviceMain.split_name(
            nameCreatorGd
          )}`;
        }

        // Approval
        if (this.$serviceMain.chechnull(nameSeniorCopyWr) !== "") {
          this.useraccess[3].user[0] = `CW : ${this.$serviceMain.split_name(
            nameSeniorCopyWr
          )}`;
        }
        if (this.$serviceMain.chechnull(nameSeniorGraphicDsn) !== "") {
          this.useraccess[3].user[1] = `GD : ${this.$serviceMain.split_name(
            nameSeniorGraphicDsn
          )}`;
        }

        //enrich

        this.enrich = String(enrich).trim();
        this.re = Date.now();
      }
    },

    get_data() {
      this.$emit("get_data");
    },
    async callModalaccess(e) {
      // this.dataaccess = e;
      this.reModalaccess = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalaccess");
      }, 100);
    },
    async callModalaccessreq(e) {
      this.dataaccessreq = e;
      this.reModalaccessreq = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalaccessreq");
      }, 100);
    },
  },
};
</script>

<style scoped></style>
