<template>
  <b-modal
    id="Modaladdfile"
    hide-footer
    no-close-on-backdrop
    hide-header-close
    scrollable
    size="lg"
  >
    <template #modal-header="">
      <div class="row w-100">
        <div class="col-10">
          <h5>
            เพิ่มไฟล์
          </h5>
        </div>
        <div class="col-2 text-right">
          <span type="button" @click="hide()">
            x
          </span>
        </div>
      </div>
    </template>
    <!-- ------------------------------------- end header ------------------- -->
    <!-- ------------- add user --------------------- -->
    <div class="row">
      <div class="col-12" v-if="mode == 'add'">
        <div class="form-group row">
          <label for="inputPassword" class="col-sm-2 col-form-label text-right"
            >รายละเอียด</label
          >
          <div class="col-sm-10">
            <input type="text" class="form-control" maxlength="200" v-model="data.remark" />
          </div>
        </div>
        <div class="form-group row">
          <label for="inputPassword" class="col-sm-2 col-form-label text-right"
            >เลือกไฟล์</label
          >
          <div class="col-sm-10">
            <input
              type="file"
              class="form-control"
              accept=".jpg, .jpeg, .png, .pdf"
              @change="upload($event)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button class="btn bt-main px-4" @click="confirm()">บันทึก</button>
          </div>
        </div>
      </div>
      <!-- ----------------------- -->
      <div class="col-12" v-else-if="mode == 'show'">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="link"
            disabled
            id="myInput"
          />
          <div class="input-group-append">
            <button type="button" class="btn btn-sm bt-main" @click="copyURL()">
              <span class="px-1" v-html="$svg_icon.link"></span>Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modaladdfile",

  data() {
    return {
      link: "",
      mode: "add",
      data: {
        dept: "",
        subDept: "",
        skuCode: "",
        brand: "",
        remark: "",
        link: "",
      },
      img: "",
    };
  },

  computed: {},
  watch: {},
  mounted() {
    this.redata();
  },
  methods: {
    redata() {
      this.link = "";
      this.mode = "add";
      this.data = {
        dept: "",
        subDept: "",
        skuCode: "",
        brand: "",
        remark: "",
        link: "",
      };
    },
    async upload(event) {
      this.img = event.target.files[0];
    },
    copyURL() {
      /* Get the text field */
      var copyText = document.getElementById("myInput");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);

      /* Alert the copied text */
      alert("Copied ");
    },
    async confirm() {
      try {
        if (!this.img) {
          throw new Error("กรุณาอัพโหลดไฟล์");
        }

        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let getdata = this.$store.getters[
            "enrichproductdetailActions/get_data"
          ];
          const { catCode, brand, skCode } = getdata;
          let data = new FormData();

          data.append("file", this.img);

          let saveimg = await this.$serviceAPI.call_API_FormData(
            "post",
            `upload`,
            data,
            0
          );

          const { path, originalname } = saveimg.data;
          let axdata = {
            link: path,
            Id: "",
            oldName: originalname,
            dept: String(catCode)
              .substring(0, 2)
              .trim(),
            subDept: String(catCode).trim(),
            skuCode: String(skCode),
            brand: String(brand).trim(),
            remark: this.data.remark,
          };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `contentFile/upload`,
            axdata,
            1
          );

          this.link = `${this.$cons.API_IMG_UPLOAD}${path}`;
          this.mode = "show";
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    hide() {
      this.$bvModal.hide("Modaladdfile");
    },
  },
};
</script>

<style scoped></style>
