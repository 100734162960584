var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid m-0 p-0",attrs:{"id":"EnrichProduct"}},[_c('div',{staticClass:"row m-0 p-3 justify-content-center"},[_c('div',{staticClass:"col-10"},[_c('b-overlay',{attrs:{"show":_vm.load,"spinner-variant":"main","spinner-type":"grow","spinner-small":""}},[_c('Boxdetail')],1),_c('b-overlay',{attrs:{"show":_vm.load,"spinner-variant":"main","spinner-type":"grow","spinner-small":""}},[_c('Productimages',{key:_vm.$route.params.skcode + 4,on:{"getpimenrichproduct":_vm.getpimenrichproduct}})],1),_c('b-overlay',{attrs:{"show":_vm.load,"spinner-variant":"main","spinner-type":"grow","spinner-small":""}},[_c('Generalinfomation',{key:_vm.$route.params.skcode + 2})],1),_c('b-overlay',{attrs:{"show":_vm.load,"spinner-variant":"main","spinner-type":"grow","spinner-small":""}},[_c('Productdescription',{key:_vm.$route.params.skcode + 6})],1),_c('b-overlay',{attrs:{"show":_vm.load,"spinner-variant":"main","spinner-type":"grow","spinner-small":""}},[_c('Productremark',{key:_vm.$route.params.skcode + 8})],1),_c('b-overlay',{attrs:{"show":_vm.load,"spinner-variant":"main","spinner-type":"grow","spinner-small":""}},[_c('Productother',{key:_vm.$route.params.skcode + 10})],1),_c('div',{staticClass:"row p-3 mb-2 sticky-bottom shadow-lg bg-white rounded"},[_c('div',{staticClass:"col-6"},[(
              _vm.enrich !== '' &&
                _vm.enrich !== 'A' &&
                _vm.$store.getters['assigntasksActions/get_dataassign'] !== null
            )?_c('div',{staticClass:"row"},[(
                _vm.$roleaction('Req Missing Data') &&
                  _vm.can_update_cw &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .copyWr_Creator_Status !== 'Y'
              )?_c('button',{staticClass:"btn btn-sm px-4 bt-vfx mx-1",attrs:{"type":"button","disabled":_vm.$store.getters['assigntasksActions/get_dataassign']
                  .copyWr_Creator_Status == 'Y' ||
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .contactVd_Cw_Status !== 'Y'},on:{"click":function($event){return _vm.RequestApproval('CW')}}},[_vm._v(" Request Approval CW "+_vm._s(_vm.$store.getters["assigntasksActions/get_dataassign"] .copyWr_Creator_Status == "R" ? " After Reject" : "")+" "),(
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .copyWr_Creator_Status == 'Y'
                )?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$svg_icon.done_b)}}):_vm._e()]):_vm._e(),(
                _vm.$roleaction('Req Image') &&
                  _vm.can_update_gd &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .graphicDsn_Creator_Status !== 'Y'
              )?_c('button',{staticClass:"btn btn-sm px-4 bt-vfx mx-1",attrs:{"type":"button","disabled":_vm.$store.getters['assigntasksActions/get_dataassign']
                  .graphicDsn_Creator_Status == 'Y' ||
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .contactVd_Gd_Status !== 'Y'},on:{"click":function($event){return _vm.RequestApproval('GD')}}},[_vm._v(" Request Approval GD "+_vm._s(_vm.$store.getters["assigntasksActions/get_dataassign"] .graphicDsn_Creator_Status == "R" ? " After Reject" : "")+" "),(
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .graphicDsn_Creator_Status == 'Y'
                )?_c('span',{staticClass:" ",domProps:{"innerHTML":_vm._s(_vm.$svg_icon.done_b)}}):_vm._e()]):_vm._e()]):_vm._e(),(
              _vm.enrich !== '' &&
                _vm.enrich !== 'A' &&
                _vm.$store.getters['assigntasksActions/get_dataassign'] !==
                  null &&
              
                String(_vm.CATTWD).length > 6
            )?_c('div',{staticClass:"row my-2"},[(
                _vm.$roleaction('Approval Copy Writer') &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .copyWr_Creator_Status == 'Y'
              )?_c('button',{staticClass:"btn btn-sm px-4 btn-info mx-1",attrs:{"type":"button","disabled":_vm.$store.getters['assigntasksActions/get_dataassign']
                  .senior_copyWr_Staus == 'Y'},on:{"click":function($event){return _vm.Approval('CW')}}},[_vm._v(" Approval CW "),(
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .senior_copyWr_Staus == 'Y'
                )?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$svg_icon.done_b)}}):_vm._e()]):_vm._e(),(
                _vm.$roleaction('Approval Copy Writer') &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .copyWr_Creator_Status == 'Y' &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .senior_copyWr_Staus !== 'Y'
              )?_c('button',{staticClass:"px-4 btn-sm btn btn-outline-danger mx-1",attrs:{"type":"button","disabled":_vm.$store.getters['assigntasksActions/get_dataassign']
                  .senior_copyWr_Staus == 'R'},on:{"click":function($event){return _vm.Reject('CW')}}},[_vm._v(" Reject CW "),(
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .senior_copyWr_Staus == 'R'
                )?_c('span',{domProps:{"innerHTML":_vm._s(_vm.$svg_icon.done_b)}}):_vm._e()]):_vm._e(),(
                _vm.$roleaction('Approval Graphic design') &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .graphicDsn_Creator_Status == 'Y'
              )?_c('button',{staticClass:"btn btn-sm px-4 btn-info mx-1",attrs:{"type":"button","disabled":_vm.$store.getters['assigntasksActions/get_dataassign']
                  .senior_graphicDsn_Status == 'Y'},on:{"click":function($event){return _vm.Approval('GD')}}},[_vm._v(" Approval GD "),(
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .senior_graphicDsn_Status == 'Y'
                )?_c('span',{staticClass:" ",domProps:{"innerHTML":_vm._s(_vm.$svg_icon.done_b)}}):_vm._e()]):_vm._e(),(
                _vm.$roleaction('Approval Graphic design') &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .graphicDsn_Creator_Status == 'Y' &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .senior_graphicDsn_Status !== 'Y'
              )?_c('button',{staticClass:"px-4 btn-sm btn btn-outline-danger mx-1",attrs:{"type":"button","disabled":_vm.$store.getters['assigntasksActions/get_dataassign']
                  .senior_graphicDsn_Status == 'R'},on:{"click":function($event){return _vm.Reject('GD')}}},[_vm._v(" Reject GD "),(
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .senior_graphicDsn_Status == 'R'
                )?_c('span',{staticClass:" ",domProps:{"innerHTML":_vm._s(_vm.$svg_icon.done_b)}}):_vm._e()]):_vm._e(),(
                _vm.$store.getters['assigntasksActions/get_dataassign']
                  .copyWr_Creator_Status == 'R'
              )?_c('button',{staticClass:"px-4 btn-sm btn btn-danger mx-1 ",on:{"click":function($event){return _vm.getDeatilReject(_vm.$route.params.skcode, 'CW')}}},[_vm._v(" Details rejected CW ")]):_vm._e(),(
                _vm.$store.getters['assigntasksActions/get_dataassign']
                  .graphicDsn_Creator_Status == 'R'
              )?_c('button',{staticClass:"px-4 btn-sm btn btn-danger mx-1 ",on:{"click":function($event){return _vm.getDeatilReject(_vm.$route.params.skcode, 'GD')}}},[_vm._v(" Details rejected GD ")]):_vm._e()]):_vm._e(),(
              _vm.enrich !== '' &&
                _vm.enrich !== 'A' &&
                _vm.$store.getters['assigntasksActions/get_dataassign'] !==
                  null &&
                String(_vm.CATBNB).length > 6 &&
                String(_vm.CATTWD).length > 6
            )?_c('div',{staticClass:"row my-2"},[(
                (_vm.$roleaction('Approval Copy Writer') &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .copyWr_Creator_Status == 'R') ||
                  (_vm.enrich !== '' &&
                    _vm.enrich !== 'I' &&
                    _vm.$store.getters['assigntasksActions/get_dataassign']
                      .copyWr_Creator_Status !== 'R' &&
                    _vm.$store.getters['assigntasksActions/get_dataassign']
                      .senior_copyWr_Staus !== 'Y')
              )?_c('button',{staticClass:"btn btn-sm px-4 btn btn-outline-dark mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.UrgentApproval('CW')}}},[_vm._v(" Urgent Approval CW ")]):_vm._e(),(
                (_vm.$roleaction('Approval Graphic design') &&
                  _vm.$store.getters['assigntasksActions/get_dataassign']
                    .graphicDsn_Creator_Status == 'R') ||
                  (_vm.enrich !== '' &&
                    _vm.enrich !== 'I' &&
                    _vm.$store.getters['assigntasksActions/get_dataassign']
                      .graphicDsn_Creator_Status !== 'R' &&
                    _vm.$store.getters['assigntasksActions/get_dataassign']
                      .senior_graphicDsn_Status !== 'Y')
              )?_c('button',{staticClass:"btn btn-sm px-4 btn btn-outline-dark mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.UrgentApproval('GD')}}},[_vm._v(" Urgent Approval GD ")]):_vm._e()]):_vm._e(),(_vm.UrgentLog.cw !== null || _vm.UrgentLog.gd !== null)?_c('div',{staticClass:"col-12"},[(_vm.UrgentLog.cw !== null)?_c('div',{staticClass:"row mb-2  align-items-center "},[(
                  _vm.UrgentLog.cw.status === 'NOFIX'
                    ? _vm.$roleaction('Req Missing Data')
                    : _vm.UrgentLog.cw.status === 'FIX'
                    ? _vm.$roleaction('Approval Copy Writer')
                    : false
                )?_c('button',{staticClass:"btn btn-sm ",class:[
                  _vm.UrgentLog.cw.status === 'NOFIX'
                    ? 'btn-outline-secondary'
                    : _vm.UrgentLog.cw.status === 'FIX'
                    ? 'btn btn-outline-success'
                    : '' ],attrs:{"type":"button"},on:{"click":function($event){_vm.UrgentLog.cw.status === 'NOFIX'
                    ? _vm.reqRejectLog('CW')
                    : _vm.UrgentLog.cw.status === 'FIX'
                    ? _vm.approvalRejectLog('CW')
                    : null}}},[_vm._v(" "+_vm._s(_vm.UrgentLog.cw.status === "NOFIX" ? "Confirm Fix Reject CW" : _vm.UrgentLog.cw.status === "FIX" ? "Pass Inspection CW" : "")+" ")]):_vm._e(),_c('p',{staticClass:"text-danger font-600 font-0-8s m-0 p-0"},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"exclamation-circle","animation":"fade","font-scale":"1"}}),_vm._v("Copy writer ยังได้รับการ "+_vm._s(_vm.UrgentLog.cw.status === "NOFIX" ? "เเก้ไขการ Reject" : _vm.UrgentLog.cw.status === "FIX" ? "ตรวจสอบหลังจากการเเก้ไข้ Reject" : "")+" ")],1)]):_vm._e(),(_vm.UrgentLog.gd !== null)?_c('div',{staticClass:"row mb-2 align-items-center"},[(
                  _vm.UrgentLog.gd.status === 'NOFIX'
                    ? _vm.$roleaction('Req Image')
                    : _vm.UrgentLog.gd.status === 'FIX'
                    ? _vm.$roleaction('Approval Graphic design')
                    : false
                )?_c('button',{staticClass:"btn btn-sm ",class:[
                  _vm.UrgentLog.gd.status === 'NOFIX'
                    ? 'btn-outline-secondary'
                    : _vm.UrgentLog.gd.status === 'FIX'
                    ? 'btn btn-outline-success'
                    : '' ],attrs:{"type":"button"},on:{"click":function($event){_vm.UrgentLog.gd.status === 'NOFIX'
                    ? _vm.reqRejectLog('GD')
                    : _vm.UrgentLog.gd.status === 'FIX'
                    ? _vm.approvalRejectLog('GD')
                    : null}}},[_vm._v(" "+_vm._s(_vm.UrgentLog.gd.status === "NOFIX" ? "Confirm Fix Reject GD" : _vm.UrgentLog.gd.status === "FIX" ? "Pass Inspection GD" : "")+" ")]):_vm._e(),_c('p',{staticClass:"text-danger font-600 m-0 p-0  font-0-8s"},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"exclamation-circle","animation":"fade","font-scale":"1"}}),_vm._v("Graphic Design ยังได้รับการ "+_vm._s(_vm.UrgentLog.gd.status === "NOFIX" ? "เเก้ไขการ Reject" : _vm.UrgentLog.gd.status === "FIX" ? "ตรวจสอบหลังจากการเเก้ไข้ Reject" : "")+" ")],1)]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row justify-content-end"},[_c('button',{staticClass:"btn btn-sm px-4 bt-main1 mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push({
                  name: 'Enrich Product',
                })}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-sm px-4 bt-bnb mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.view()}}},[_vm._v(" View ")]),(
                _vm.can_update &&
                  (_vm.$roleaction('Req Image') ||
                    _vm.$roleaction('Req Missing Data'))
              )?_c('button',{staticClass:"btn btn-sm bt-vfx mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.savevdo()}}},[_vm._v(" Save Videos ")]):_vm._e(),(
                _vm.can_update &&
                  _vm.$roleaction('Req Image') &&
                  (_vm.enrich == 'I' && _vm.can_update_gd
                    ? true
                    : _vm.enrich == 'C' ||
                      _vm.enrich == 'Y' ||
                      _vm.enrich == 'P' ||
                      _vm.enrich == 'R' ||
                      _vm.enrich == 'A'
                    ? true
                    : false)
              )?_c('button',{staticClass:"btn btn-sm bt mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveimg()}}},[_vm._v(" Save Images ")]):_vm._e(),(
                _vm.can_update &&
                  _vm.$roleaction('Req Missing Data') &&
                  (_vm.enrich == 'I' && _vm.can_update_cw
                    ? true
                    : _vm.enrich == 'C' ||
                      _vm.enrich == 'Y' ||
                      _vm.enrich == 'P' ||
                      _vm.enrich == 'R' ||
                      _vm.enrich == 'A'
                    ? true
                    : false)
              )?_c('button',{staticClass:"btn btn-sm px-4 bt-main mx-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.update()}}},[_vm._v(" Save Content ")]):_vm._e()])])])],1),_c('div',{staticClass:"col-2 m-0 pr-0"},[_c('div',{staticClass:"m-0 px-0 pt-1 pb-5 w-100 sticky-top"},[_c('div',{staticClass:"row m-0 p-0 w-100"},[_c('div',{staticClass:"col-12 m-0 p-0"},[_c('p',{staticClass:"m-0 py-2 px-3",class:_vm.scrollnow == 'Productimages'
                  ? 'scrollselect'
                  : 'scrollunselect',attrs:{"type":"button"},on:{"click":function($event){return _vm.scroll('Productimages')}}},[_vm._v(" รูปภาพเเละวิดีโอของสินค้า ")]),_c('p',{staticClass:"m-0 py-2 px-3",class:_vm.scrollnow == 'Generalinfomation'
                  ? 'scrollselect'
                  : 'scrollunselect',attrs:{"type":"button"},on:{"click":function($event){return _vm.scroll('Generalinfomation')}}},[_vm._v(" ข้อมูลทั่วไป ")]),_c('p',{staticClass:"m-0 py-2 px-3",class:_vm.scrollnow == 'Productdescription'
                  ? 'scrollselect'
                  : 'scrollunselect',attrs:{"type":"button"},on:{"click":function($event){return _vm.scroll('Productdescription')}}},[_vm._v(" คำอธิบายของสินค้า ")]),_c('p',{staticClass:"m-0 py-2 px-3",class:_vm.scrollnow == 'Productremark'
                  ? 'scrollselect'
                  : 'scrollunselect',attrs:{"type":"button"},on:{"click":function($event){return _vm.scroll('Productremark')}}},[_vm._v(" Remark ของสินค้า ")]),_c('p',{staticClass:"m-0 py-2 px-3",class:_vm.scrollnow == 'Productother'
                  ? 'scrollselect'
                  : 'scrollunselect',attrs:{"type":"button"},on:{"click":function($event){return _vm.scroll('Productother')}}},[_vm._v(" อื่น ๆ ")])])]),_c('div',{staticClass:"row w-100 m-0 p-0"},[_c('div',{staticClass:"col-12 m-0 p-0"},[_c('Access',{key:_vm.$route.params.skcode,on:{"get_data":_vm.get_data}})],1)])])]),(_vm.scrolldown > 500)?_c('div',{staticClass:"m-0 p-0 gototop"},[_c('button',{staticClass:"btn bt-transparent",attrs:{"type":"button"},on:{"click":function($event){return _vm.gototop()}}},[_c('b-avatar',{staticClass:"text-main",attrs:{"variant":"light","icon":"caret-up-fill","size":"3em"}})],1)]):_vm._e()]),_c('ModalReject',{key:_vm.reModal,attrs:{"propsData":_vm.dataModal}}),_c('ModalUrgentApproval',{key:_vm.keyUrgent,attrs:{"propsData":_vm.dataUrgent},on:{"calldata":_vm.get_data}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }