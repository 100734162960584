import { render, staticRenderFns } from "./Productimages.vue?vue&type=template&id=1fdcb48b&scoped=true&"
import script from "./Productimages.vue?vue&type=script&lang=js&"
export * from "./Productimages.vue?vue&type=script&lang=js&"
import style0 from "./Productimages.vue?vue&type=style&index=0&id=1fdcb48b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fdcb48b",
  null
  
)

export default component.exports