<template>
  <div id="Productdescription" class="row">
    <div class="col-12 p-5 shadow p-3 mb-3 bg-white rounded">
      <p class="font-1-2s font-600">คำอธิบายของสินค้า</p>
      <div class="text-right mb-1">
        <div
          class="btn-group"
          role="group"
          aria-label="Basic example"
          v-for="(e, i) in data.CatTree"
          :key="i"
        >
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="คลิกเพื่อดาวน์โหลด Contect Guideline"
            type="button"
            class="btn btn-link btn-sm p-1"
            @click="getContentPdf(e)"
          >
            {{ String(e).split(":")[1] }}
          </button>
        </div>
      </div>
      <div class="row">
        <div
          class="position-relative shadow  bg-white rounded"
          :class="openatt ? 'col-12 col-lg-4 ' : ' '"
        >
          <div class="m-0 px-3 w-100 mb-3" v-if="openatt">
            <div class="row m-0 p-0 align-items-center">
              <div class="col-6 m-0 p-0">
                <p class="mt-3">ข้อมูลเฉพาะสินค้า</p>
              </div>
              <div class="col-6 m-0 p-0 text-right">
                <button
                  type="button"
                  class="btn btn-sm bt-main mx-1"
                  @click="manageattribute()"
                >
                  Manage Attribute
                </button>
              </div>
            </div>

            <hr />
            <div
              class="row p-1 font-0-8s"
              v-for="(e, i) in att"
              :key="i"
              :class="even(i) ? '' : 'att-even'"
            >
              <div class="col-6 font-600">{{ e.PropName }}</div>
              <div class="col-6">{{ e.PropDetail }}</div>
            </div>
          </div>
          <!-- --------------------------- -->
          <!-- v-if="openatt" -->
          <div class="mx-0 mt-4 px-3 w-100" v-if="openatt">
            <p class="">บันทึก/ลิงค์ไฟล์ของสินค้า</p>
            <hr />
            <!-- <button class="  btn bt-bnb mx-1 px-3" @click="CallModaladdfile()">
              <span v-html="$svg_icon.add" class="mx-1"></span>เพิ่มไฟล์
            </button> -->
            <button
              class="  btn bt-main mx-1 px-3"
              @click="CallModallinkfile()"
            >
              <span v-html="$svg_icon.link" class="mx-1"></span>Link Product
              Files List
            </button>
            <!-- <div
              v-if="link_img_dec.length == 0"
              class="row p-1 font-0-8s overflow-img-dec"
            >
              <div class="col-12">
                <p class="text-center text-danger">ไม่มีข้อมูล</p>
              </div>
            </div> -->
            <!-- ------------------ v-else ----------------- -->
            <!-- <div v-else class="row p-1 font-0-8s overflow-img-dec">
              <div class="col-12">
                <div
                  class="row mb-1 align-items-center"
                  v-for="(e, i) in link_img_dec"
                  :key="i"
                >
                  <div class="col-6 text-center">
                    <img :src="e.img" class="img-fluid box-img" alt="" />
                  </div>
                  <div class="col-6">
                    <button
                      type="button"
                      class="btn btn-sm bt-main"
                      @click="copyURL(e.img)"
                    >
                      <span class="px-1" v-html="$svg_icon.link"></span>Copy
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- ----------------------- -->
          <div
            class="call-att d-flex align-items-center"
            type="button"
            @click="openatt = !openatt"
          >
            <p
              v-if="openatt"
              class="text-center  text-main m-0 p-0 w-100"
              v-html="$svg_icon.arrowl"
            ></p>
            <p
              v-else
              class="text-center   text-main m-0 p-0 w-100"
              v-html="$svg_icon.arrowr"
            ></p>
          </div>
        </div>
        <div class="" :class="openatt ? 'col-12 col-lg-8' : 'col-12'">
          <!-- --------------------------- -->
          <div class="row px-3 mb-3">
            <div class="col-12">
              <p>Product Short Description</p>
              <hr />
            </div>

            <div class="col-6">
              <p class="badge badge-primary">TH</p>
              <tinymce
                class="shadow bg-white rounded"
                id="DescriptionTH"
                :plugins="plugins"
                :toolbar="toolbar"
                :other_options="options"
                v-model="data.shortDesc1TH"
              ></tinymce>
            </div>
            <div class="col-6">
              <p class="badge badge-info">EN</p>
              <tinymce
                class="shadow bg-white rounded"
                id="DescriptionEN"
                :plugins="plugins"
                :toolbar="toolbar"
                :other_options="options"
                v-model="data.shortDesc1EN"
              ></tinymce>
            </div>
          </div>
          <!-- --------------------------- -->

          <!-- -------------------------------------- -->
          <div class="row px-3">
            <div class="col-12">
              <p>Product Long Description</p>
              <hr />
            </div>

            <div class="col-6">
              <p class="badge badge-primary">TH</p>
              <tinymce
                class="shadow bg-white rounded"
                id="LonngDescriptionTH"
                :plugins="plugins"
                :toolbar="toolbar"
                :other_options="options"
                v-model="data.longDescTH"
              ></tinymce>
            </div>
            <div class="col-6">
              <p class="badge badge-info">EN</p>
              <tinymce
                class="shadow bg-white rounded"
                id="LonngDescriptionEN"
                :plugins="plugins"
                :toolbar="toolbar"
                :other_options="options"
                v-model="data.longDescEN"
              ></tinymce>
            </div>
          </div>

          <!-- -------------------------------------- -->
        </div>
      </div>
    </div>
    <Modaladdfile :key="reModaladdfile" />
    <Modallinkfile :key="reModallinkfile" />
  </div>
</template>

<script>
import tinymce from "vue-tinymce-editor";
import Modaladdfile from "@/components/Products/EnrichProductDetail/Productdescription/Modaladdfile.vue";
import Modallinkfile from "@/components/Products/EnrichProductDetail/Productdescription/Modallinkfile.vue";
export default {
  components: { tinymce, Modaladdfile, Modallinkfile },

  data() {
    return {
      // Tinymce Editor
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 150,
        relative_urls: false,
        remove_script_host: false,
        convert_urls: true,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      // End Tinymce Editor
      openatt: true,
      // link_img_dec: [],
      link_img_dec: [
        {
          img: `https://i.pinimg.com/736x/79/dc/52/79dc52b02e062f80fae99e5c395ae1ed.jpg`,
        },
        {
          img: `https://i.pinimg.com/236x/b4/29/0f/b4290f21445bcec9d33ef0754441bdb6.jpg`,
        },
        {
          img: `https://i.pinimg.com/236x/49/4d/16/494d16b89652d7162528b100789c7f4c.jpg`,
        },
        {
          img: `https://i.pinimg.com/236x/94/ec/8e/94ec8ea8a441230e52e3776c0f34d139.jpg`,
        },
      ],
      att: [],
      data: {
        longDescEN: "",
        longDescTH: "",
        shortDesc1EN: "",
        shortDesc1TH: "",

        CatTree: { cat1: "", cat2: "", cat3: "" },
      },

      reModaladdfile: Date.now() + 20,
      reModallinkfile: Date.now() + 120,
    };
  },
  watch: {
    com_get_att: function(v) {
      this.get_att();
    },
    com_get_data: function(v) {
      this.get_data();
    },
    data: {
      async handler(val) {
        await this.$store.dispatch("enrichproductdetailActions/setdesc", val);
      },
      deep: true,
    },
  },
  computed: {
    com_get_att: function() {
      return this.$store.getters["enrichproductdetailActions/get_att"];
    },
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    manageattribute() {
      this.$router.push({
        name: "Attributes-Products-Detail",
        params: { skcode: this.$route.params.skcode },
        query: { content: 1 },
      });
    },
    CallModallinkfile() {
      this.reModallinkfile = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modallinkfile");
      }, 100);
    },
    CallModaladdfile() {
      this.reModaladdfile = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modaladdfile");
      }, 100);
    },
    async get_data() {
      // this.redata();
      let data = this.$store.getters["enrichproductdetailActions/get_data"];

      if (data !== null) {
        const {
          longDescEN,
          longDescTH,
          shortDesc1EN,
          shortDesc1TH,

          CAT1,
          CAT2,
          CAT3,
        } = data;

        this.data = {
          longDescEN: longDescEN,
          longDescTH: longDescTH,
          shortDesc1EN: shortDesc1EN,
          shortDesc1TH: shortDesc1TH,

          CatTree: { cat1: CAT1, cat2: CAT2, cat3: CAT3 },
        };
      }
    },
    async get_att() {
      let get_att = this.$store.getters["enrichproductdetailActions/get_att"];
      if (get_att !== null && get_att.length > 0) {
        this.att = get_att;
      }
    },
    even(i) {
      if (i % 2 == 0) {
        return true;
      } else {
        return false;
      }
    },
    copyURL(img) {
      this.$copyText(img).then(
        function(e) {
          alert("Copied");
          // console.log(e);
        },
        function(e) {
          alert("Can not copy");
          // console.log(e);
        }
      );
    },
    async getContentPdf(value) {
      try {
        var catCode = String(value).split(":")[0];
        var catName = String(value).split(":")[1];
        var data = {};
        let getData = await this.$serviceAPI.call_API_Getfile(
          "get",
          `product/getcontentguideline/${catCode}`,
          data,
          1
        );
        let blob = new Blob([getData.data], { type: "application/pdf" }),
          url = window.URL.createObjectURL(blob);
        window.open(url);
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          `ขออภัยไม่พบ Content Guildline ${catCode} ${catName.replace(
            />>/g,
            ""
          )} `
        );
      }
    },
  },
};
</script>

<style scoped>
.box-img {
  height: 100px;
  width: 100px;
}
.att-even {
  background-color: rgb(228, 228, 228);
}
.call-att {
  position: absolute;
  right: -20px;
  top: 25%;
  transform: translate(0%, -50%);
  width: 50px;
  height: 50px;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 1px 1px 4px #b8b8b8;
  background-color: white;
  border-radius: 25px;
}
.overflow-img-dec {
  /* height: 50vh; */
  overflow-y: auto;
}
</style>
