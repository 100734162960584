<template>
  <b-modal
    id="Modalaccessreq"
    hide-footer
    no-close-on-backdrop
    hide-header-close
    scrollable
    size="xl"
  >
    <template #modal-header="">
      <div class="row w-100">
        <div class="col-10">
          <h5>
            {{ stepname }}
          </h5>
        </div>
        <div class="col-2 text-right">
          <span type="button" @click="hide()">
            x
          </span>
        </div>
      </div>
    </template>
    <!-- ------------------------------------- end header ------------------- -->
    <!-- ------------- add user --------------------- -->
    <div class="row">
      <div class="col-12  ">
        <div class="row mb-3">
          <div class="col-6 col-xl-2 text-right">อีเมลล์ผู้รับผิดชอบ</div>
          <div class="col-6 col-xl-10">
            <input
              class="form-control form-control-sm"
              type="text"
              disabled
              v-model="data.user_email"
            />
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="row mb-2">
          <div class="col-6 col-xl-2 text-right">ส่งถึงอีเมลล์</div>
          <div class="col-6 col-xl-10">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="data.to_email"
            />
            <p class="font-0-7s text-muted m-0 p-0">
              Example : Example1@chg.co.th,Example2@chg.co.th
            </p>
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="row mb-3">
          <div class="col-6 col-xl-2 text-right">สำเนาถึงอีเมลล์</div>
          <div class="col-6 col-xl-10">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="data.cc_email"
            />
            <p class="font-0-7s text-muted m- p-0">
              Example : Example1@chg.co.th,Example2@chg.co.th
            </p>
          </div>
        </div>
        <!-- --------------------------- -->
        <div class="row mb-3">
          <div class="col-6 col-xl-2 text-right">เรื่อง</div>
          <div class="col-6 col-xl-10">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="data.subject"
            />
          </div>
        </div>
        <!-- --------------------------- -->
      </div>
      <div class="col-12 border p-3 m-0 rounded">
        <div class="row mb-3" v-if="mode == 'data'">
          <div class="col-6 col-xl-2 text-right">Recrieve (Date)</div>
          <div class="col-6 col-xl-4">
            <input
              class="form-control form-control-sm"
              type="date"
              v-model="data.recrieve_Date"
            />
          </div>
        </div>
        <div class="row mb-3" v-if="mode == 'img'">
          <div class="col-6 col-xl-2 text-right">จำนวนมุมภาพที่มี</div>
          <div class="col-6 col-xl-4">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="data.qty_Corners"
            />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6 col-xl-2 text-right">Progress / Action​</div>
          <div class="col-6 col-xl-10">
            <input
              class="form-control form-control-sm"
              type="text"
              v-model="data.progress_Action"
            />
          </div>
        </div>
        <p class=" font-600">
          {{ substepname }}
        </p>

        <div class="row">
          <div class="col-6" v-for="(e, i) in reqlist" :key="i">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                :value="e"
                :id="e"
                v-model="req"
              />
              <label class="form-check-label" :for="e">
                {{ `${e !== "OTHER" ? e : "อื่นๆ..."}` }}
                <input
                  v-if="e == 'OTHER' && selectother"
                  class="form-control"
                  type="text"
                  placeholder="อื่นๆ..."
                  v-model="req_other"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-right mt-3">
        <button type="button" class="btn bt-main" @click="confirm()">
          บันทึก
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modalaccessreq",
  props: ["dataaccess"],
  data() {
    return {
      stepname: "",
      substepname: "",
      mode: "",
      selectother: false,
      selectotherImage: false,

      data: {
        assign_Id: "",
        user_email: "",
        to_email: "",
        cc_email: "",
        subject: "",
        missing_Data: [],
        num_Corners: "",
        req_Image: [],
        qty_Corners: "",
        progress_Action: "",
        timeLine: null,
        assign_Date: null,
        assign_By: "",
        sent_Date: null,
        recrieve_Date: null,
      },
      reqlist: [],

      req: [],
      req_other: "",
    };
  },

  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
    com_dataassign: function() {
      return this.$store.getters["assigntasksActions/get_dataassign"];
    },
  },
  watch: {
    com_dataassign(v) {
      this.initdata_assign();
    },
    com_get_data: function(v) {},

    req: function() {
      let type = typeof this.req;
      if (this.mode == "data") {
        this.data.missing_Data = this.req;
      } else if (this.mode == "img") {
        this.data.req_Image = this.req;
      }

      if (type == "object") {
        let find = this.req.findIndex((e) => e.split(":")[0] == "OTHER");
        if (find !== -1) {
          this.req_other = this.req[find].split(":")[1];
          this.req[find] = this.req[find].split(":")[0];
        }
      }

      if (type == "object") {
        let find = this.req.findIndex((e) => e == "OTHER");
        if (find !== -1) {
          this.selectother = true;
        } else {
          this.selectother = false;
        }
      } else {
        this.selectother = false;
      }
    },
    data: {
      async handler(val) {
        await this.$store.dispatch("assigntasksActions/set_Assignreq", val);
      },
      deep: true,
    },
    // "data.req_Image": function() {
    //   let type = typeof this.data.req_Image;

    //   if (type == "object") {
    //     let find = this.data.req_Image.findIndex((e) => e == "OTHER");
    //     if (find !== -1) {
    //       this.selectotherImage = true;
    //     } else {
    //       this.selectotherImage = false;
    //     }
    //   } else {
    //     this.selectotherImage = false;
    //   }
    // },
    // "data.missing_Data": function() {
    //   let type = typeof this.data.missing_Data;

    //   if (type == "object") {
    //     let find = this.data.missing_Data.findIndex((e) => e == "OTHER");
    //     if (find !== -1) {
    //       this.selectother = true;
    //     } else {
    //       this.selectother = false;
    //     }
    //   } else {
    //     this.selectother = false;
    //   }
    // },
  },
  async mounted() {
    this.initdata();
    this.getuseraccessbyid();
  },
  methods: {
    async getuseraccessbyid() {
      this.$store.dispatch("assigntasksActions/call_dataassign");
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async initdata() {
      this.reqlist = [];
      let data = this.dataaccess;

      const { name, user, id, mode, email } = data;
      this.stepname = name;
      this.data.user_email = email;
      this.mode = mode;
      if (mode == "data") {
        this.substepname = "ข้อมูลที่ขาด / ขอข้อมูลเพิ่ม";
        await this.$store.dispatch("assigntasksActions/call_missing_Data");
        let getter = await this.$store.getters[
          "assigntasksActions/get_missing_Data"
        ];

        this.reqlist = await this.value_array(getter);
      } else if (mode == "img") {
        this.substepname = "ภาพที่ขอเพิ่ม";
        await this.$store.dispatch("assigntasksActions/call_req_Image");
        let getter = await this.$store.getters[
          "assigntasksActions/get_req_Image"
        ];
        this.reqlist = await this.value_array(getter);
      }
    },
    async initdata_assign() {
      let data = this.$store.getters["assigntasksActions/get_dataassign"];

      if (data !== null) {
        const {
          missing_Data,
          progress_Action,
          qty_Corners,
          recrieve_Date,
          req_Image,
          user_email,
          to_email,
          cc_email,
          subject,
        } = data;
        this.data.missing_Data = missing_Data;
        this.data.qty_Corners = qty_Corners;
        this.data.progress_Action = progress_Action;
        this.data.recrieve_Date = recrieve_Date;
        this.data.req_Image = req_Image;
        this.data.user_email =
          user_email !== undefined ? user_email : this.data.user_email;
        this.data.to_email =
          to_email !== undefined ? to_email : this.data.to_email;
        this.data.cc_email =
          cc_email !== undefined ? cc_email : this.data.cc_email;
        this.data.subject = subject !== undefined ? subject : this.data.subject;

        if (this.mode == "data") {
          this.req = missing_Data.split(",");
        } else if (this.mode == "img") {
          this.req = req_Image.split(",");
        }
      }
    },
    async value_array(data) {
      return new Promise(async (resolve, reject) => {
        var arr = [];
        for await (var [i, v] of data.entries()) {
          arr.push(v.detail);
        }
        resolve(arr);
      });
    },

    async confirm() {
      try {
        let getterdata = this.$store.getters[
          "enrichproductdetailActions/get_data"
        ];
        const { assign_Id } = getterdata;
        this.data.assign_Id = this.$serviceMain.chechnull(assign_Id);
        this.data.remark = this.req;
        let find = this.data.remark.findIndex((e) => e == "OTHER");
        if (find !== -1) {
          this.data.remark[find] = `OTHER:${this.req_other}`;
        }
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let data = this.$store.getters["assigntasksActions/get_dataassign"];
          data.skCode = this.$route.params.skcode;

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `access/accessuser`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");

          this.redata();
          this.hide();
        }
      } catch (error) {
        console.log(error);
        alert(JSON.stringify(error));
      }
    },
    redata() {
      this.data = {
        assign_Id: "",
        user_email: "",
        to_email: "",
        cc_email: "",
        subject: "",
        missing_Data: [],
        num_Corners: "",
        req_Image: [],
        qty_Corners: "",
        progress_Action: "",
        timeLine: null,
        assign_Date: null,
        assign_By: "",
        sent_Date: null,
        recrieve_Date: null,
      };
      this.reqlist = [];

      this.req = [];
      this.req_other = "";
    },

    hide() {
      this.$bvModal.hide("Modalaccessreq");
    },
  },
};
</script>

<style scoped>
.locat {
  position: absolute;
  top: 0px;
  left: -5px;
}
</style>
