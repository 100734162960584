<template>
  <b-modal
    id="Modalaccess"
    hide-footer
    no-close-on-backdrop
    hide-header-close
    scrollable
    size="xl"
  >
    <template #modal-header="">
      <div class="row w-100">
        <div class="col-10">
          <h5>
            Overall Process​
            <span class="font-0-8s text-black-50">{{ stepname }}</span>
          </h5>
        </div>
        <div class="col-2 text-right">
          <span type="button" @click="hide()">
            x
          </span>
        </div>
        <!-- -------------------- stap ------------------- -->
        <div class="col-12 mt-2 ">
          <div class="row overflowstage justify-content-center">
            <div
              class="col-2  d-flex align-items-center pt-2 px-0 justify-content-between position-relative mybox"
              v-for="(e, i) in steper"
              :key="i"
            >
              <div class="w-75">
                <div
                  v-if="e.step !== 0"
                  :class="steper[i-1].select ? 'completeline' : 'line'"
                ></div>
              </div>
              <div class="m-0 p-0" type="button" @click="setstep(i)">
                <b-avatar
                  class=""
                  :text="`${e.step + 1}`"
                  :class="e.select ? 'boxdone' : 'boxdone-n'"
                >
                </b-avatar>
              </div>

              <span class="container p-0">
                <!-- <span class="font-0-7s  mx-1    ">{{ e.name }}</span> -->
                <div class="">
                  <div
                    v-if="e.step !== 1"
                    :class="e.select ? 'completeline' : 'line'"
                  ></div>
                </div>
                <!-- <span class="font-0-7s  mx-1  text-bnb   ">{{
                  e.assign_name
                }}</span> -->
              </span>

              <span
                v-if="step == e.step"
                class="locat text-twd  "
                v-html="$svg_icon.locat_b"
              >
              </span>
              <span class="stepname">{{ e.name }}</span>
            </div>
          </div>
        </div>
        <!-- ---------------------- end step ------------ -->
        <!-- <div class="col-12 col-xl-6">
          <div class="input-group  ">
            <div class="input-group-prepend">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>

            <input
              type="search"
              class="form-control"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn bt-main" @click="Search()">ค้นหา</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 text-right">
          <div class="form-group row">
            <label for="inputPassword" class="col-sm-2 col-form-label"
              >TimeLine</label
            >
            <div class="col-sm-6">
              <input type="date" class="form-control" v-model="data.timeLine" />
            </div>
            <button
              type="button"
              class="btn  bt-main mx-1 col-sm-2"
              @click="confirm()"
            >
              บันทึก
            </button>
          </div>
        </div> -->
      </div>
    </template>
    <!-- ------------------------------------- end header ------------------- -->
    <!-- ------------- add user --------------------- -->
    <div class="row">
      <Preqc v-if="step == 0" @hide_and_get="hide_and_get" />
      <Contactvendor v-else-if="step == 1" @hide_and_get="hide_and_get" />
      <!-- <CreateNewProduct v-else-if="step == 2" @hide_and_get="hide_and_get" />
      <Approval v-else-if="step == 3" @hide_and_get="hide_and_get" /> -->
    </div>

    <div class="row my-2">
      <div class="col-12 text-right">
        <button
          type="button"
          class="btn  btn-sm bt-main mx-1"
          :disabled="step === 0"
          @click="goback()"
        >
          <span v-html="$svg_icon.arrowl"></span></button
        ><button
          type="button"
          class="btn  btn-sm bt-main mx-1"
          :disabled="step === 1"
          @click="go()"
        >
          <span v-html="$svg_icon.arrowr"></span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Preqc from "../../../components/Products/EnrichProductDetail/Modalaccess/Preqc.vue";
import Contactvendor from "../../../components/Products/EnrichProductDetail/Modalaccess/Contactvendor.vue";
// import CreateNewProduct from "../../../components/Products/EnrichProductDetail/Modalaccess/CreateNewProduct.vue";
// import Approval from "../../../components/Products/EnrichProductDetail/Modalaccess/Approval.vue";
export default {
  name: "Modalaccess",
  props: ["dataaccess"],
  components: {
    Preqc,
    Contactvendor,
    // CreateNewProduct, Approval
  },
  data() {
    return {
      steper: [
        { name: "Pre QC", step: 0, select: false, assign_name: " " },
        {
          name: "Contact vendor",
          step: 1,
          select: false,
          assign_name: " ",
        },
        // {
        //   name: "Create New Product",
        //   step: 2,
        //   select: false,
        //   assign_name: " ",
        // },
        // {
        //   name: "Approval",
        //   step: 3,
        //   select: false,
        //   assign_name: " ",
        // },
      ],
      step: 0,
      stepname: "",
      mode: "email",
      find: "",
      selectother: false,
      selectotherImage: false,
      select_mode: [
        { name: "ค้นหาด้วย FirstName", key: "firstname" },
        { name: "ค้นหาด้วย LastName", key: "lastname" },
        { name: "ค้นหาด้วย Email", key: "email" },
      ],
      fields: [
        { key: "firstname", label: "FirstName" },
        { key: "lastname", label: "LastName" },
        { key: "email", label: "Email" },
        { key: "permission_name", label: "PermissionName" },

        { key: "action", label: "" },
      ],
      data: {
        assign_Id: "",
        permission_id: "",
        permission_name: "",
        copyWr_Qc: "",
        copyWr_Creator: "",
        graphicDsn_Qc: "",
        copyWr_Qc_name: "",
        copyWr_Creator_Status: "N",
        copyWr_Qc_Status: "N",
        graphicDsn_Qc_Status: "N",
        copyWr_Creator_name: "",
        graphicDsn_Qc_name: "",
        firstname: "",
        lastname: "",
        email: "",
        user_id: "",
        missing_Data: [],
        num_Corners: "",
        req_Image: [],
        remark: "",
        qty_Corners: "",
        progress_Action: "",
        timeLine: null,
        assign_Date: null,
        assign_By: "",
        sent_Date: null,
        recrieve_Date: null,
        remark: [],
        textother_req_Image: "",
        textother_missing_Data: "",
      },
      missing_Data: [],
      req_Image: [],
      useraccess: [
        {
          name: "Copy writer (QC)",
          permission_id: "892b01ea-ed27-43da-b25e-d09d0b3cb035",
          user: "",
          mode: "data",
          step: 1,
        },
        {
          name: "Copy writer (Creator)",
          permission_id: "21ee592c-39b9-4a58-abff-11607b93d04e",
          user: "jaisangma16",
          mode: "data",
          step: 2,
        },
        {
          name: "Graphic design (QC)",
          permission_id: "747bb93f-d278-415c-8329-edf9b0566097",
          user: "",
          mode: "image",
          step: 3,
        },
      ],
      remark: [],
      itemlist: [],
      items: [],
    };
  },

  computed: {
    com_get_data: function() {
      return this.$store.getters["enrichproductdetailActions/get_data"];
    },
    com_dataassign: function() {
      return this.$store.getters["assigntasksActions/get_dataassign"];
    },
  },
  watch: {
    com_dataassign(v) {
      this.initdata_assign();
    },
    com_get_data: function(v) {
      // this.initdata();
    },

    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
    data: {
      async handler(val) {
        await this.$store.dispatch("assigntasksActions/set_AssignTasks", val);
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getuser();

    // this.getuseraccess(this.step);
    // this.initdata();
    //  this.getuseraccessbyid();

    this.initdata_assign();
  },
  methods: {
    setstep(i) {
      this.step = i;
    },
    async getuseraccessbyid() {
      this.$store.dispatch("assigntasksActions/call_dataassign");
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },

    async getuser() {
      // await this.$store.dispatch("assigntasksActions/call_copyWr_Qc");
      // await this.$store.dispatch("assigntasksActions/call_copyWr_Creator");
      // await this.$store.dispatch("assigntasksActions/call_graphicDsn_Qc");

      await this.$store.dispatch("assigntasksActions/call_req_Image");
      await this.$store.dispatch("assigntasksActions/call_missing_Data");
    },
    // async initdata() {
    //   let data = this.$store.getters["enrichproductdetailActions/get_data"];

    //   if (data !== null) {
    //     const { idQc, nameQc, idCt, nameCreator, idDsn, nameGraphicDsn } = data;
    //     this.data.copyWr_Qc = idQc;
    //     this.data.copyWr_Creator = idCt;
    //     this.data.graphicDsn_Qc = idDsn;
    //     this.data.copyWr_Qc_name = nameQc;
    //     this.data.copyWr_Creator_name = nameCreator;
    //     this.data.graphicDsn_Qc_name = nameGraphicDsn;
    //   }
    // },
    async initdata_assign() {
      let data = await this.$store.getters["assigntasksActions/get_dataassign"];

      if (data !== null) {
        const {
          copyWr_Qc,
          graphicDsn_Qc,
          contact_vendor,
          copyWr_Creator_Status,
          copyWr_Creator,
          contact_vendor_Status,
          senior_copyWr_Staus,
          copyWr_Qc_Status,
          contactVd_Cw_Status,
          contactVd_Gd_Status,
          graphicDsn_Qc_Status,
          senior_graphicDsn_Status,
        } = data;
        //0
        this.steper[0].select =
          copyWr_Qc_Status == "Y" && graphicDsn_Qc_Status == "Y" ? true : false;
        //1
        this.steper[1].select =
          contactVd_Cw_Status == "Y" && contactVd_Gd_Status == "Y"
            ? true
            : false;

        //2
        // this.steper[2].select =
        //   this.$serviceMain.chechnull(copyWr_Creator) !== "" &&
        //   copyWr_Creator_Status == "Y"
        //     ? true
        //     : false;

        // //2
        // this.steper[3].select =
        //   senior_graphicDsn_Status == "Y" && senior_copyWr_Staus == "Y"
        //     ? true
        //     : false;

        // set init step
        for await (var v of this.steper) {
          if (v.select) {
            this.go();
          }
        }
      }
    },

    goback() {
      this.step--;
    },
    go() {
      this.step++;
      if (this.step > 1) {
        this.step = 1;
      }
    },

    hide_and_get() {
      this.$emit("get_data");
      this.hide();
    },

    hide() {
      this.$bvModal.hide("Modalaccess");
    },
  },
};
</script>

<style scoped>
.locat {
  position: absolute;
  top: -5px;
  left: 45%;
  transform: translate(-50%, 0%);
}
.stepname {
  position: absolute;
  height: 12px;
  font-size: 12px;
  font-weight: 600;
  bottom: -10px;
  left: 45%;
  transform: translate(-50%, 0%);
}
.mybox {
  margin-top: 10px;
  margin-bottom: 18px;
}
</style>
